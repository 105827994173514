import moment from 'moment'
import 'moment/locale/ko'
import React, { useEffect, useState } from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import { IoVolumeHighSharp, IoVolumeMute } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { searchPartnerUrl } from '../../api/authManage/authManageApi'
import { createReloadCheckLog } from '../../api/reloadCheck/reloadCheck'
import { fetchJackpot, fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import { toggleLeftMenuSidebarShow } from '../../redux/modalSlice'
import { setSelectedSportId as setDomesticSelectedSportId } from '../../redux/sportsDomesticInfoSlice'
import { setSelectedSportId as setLiveSelectedSportId } from '../../redux/sportsLiveInfoSlice'
import { setSelectedSportId as setOverseasSelectedSportId } from '../../redux/sportsOverseasInfoSlice'
import { setSelectedSportId as setSpecialSelectedSportId } from '../../redux/sportsSpecialInfoSlice'
import { handleDeleteAuthentication } from '../../socketio/userAuthSocketIOHandler'
import isLogin, { setRecommendCode } from '../../utils/enums/authority'
import { HermesMainBorder, HermesMainHover2, HermesMainText } from '../../utils/palette'
import { decodeAccessToken, deleteAccessToken, getAccessToken } from '../../utils/token'
import CustomAlert from '../CustomAlert'
import AuthorityAlertPopup from '../Popup/Error/AuthorityAlertPopup'
import SignInModal from './SignInModal'
import SignUpModal from './SignUpModal'

const Header = () => {
  const loginCheck = isLogin()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const [currentTime, setCurrentTime] = useState(moment().tz('Asia/Seoul'))

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().tz('Asia/Seoul'))
    }, 1000)
    return () => clearInterval(timer)
  }, [])

  const { isShowLeftMenuSidebar } = useSelector(state => {
    const { modalInfo } = state

    return {
      isShowLeftMenuSidebar: modalInfo.isShowLeftMenuSidebar,
    }
  })

  const {
    oneToOneRedisSize,
    noteRedisSize,
    reloadCount,
    reloadTime,
    realTimeDepositList,
    realTimeWithdrawalList,
    domesticSportsUsing,
    domesticSportsName,
    overseasSportsUsing,
    overseasSportsName,
    specialSportsUsing,
    specialSportsName,
    liveSportsUsing,
    liveSportsName,
    revolutionHoldemInfo,
    wildHoldemInfo,
  } = useSelector(state => {
    const { memberInfo } = state

    return {
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
      noteRedisSize: memberInfo.noteRedisSize,
      reloadCount: memberInfo.reloadCount,
      reloadTime: memberInfo.reloadTime,
      realTimeDepositList: memberInfo.realTimeDepositList,
      realTimeWithdrawalList: memberInfo.realTimeWithdrawalList,
      domesticSportsUsing: memberInfo.domesticSportsUsing,
      domesticSportsName: memberInfo.domesticSportsName,
      overseasSportsUsing: memberInfo.overseasSportsUsing,
      overseasSportsName: memberInfo.overseasSportsName,
      specialSportsUsing: memberInfo.specialSportsUsing,
      specialSportsName: memberInfo.specialSportsName,
      liveSportsUsing: memberInfo.liveSportsUsing,
      liveSportsName: memberInfo.liveSportsName,
      revolutionHoldemInfo: memberInfo.revolutionHoldemInfo,
      wildHoldemInfo: memberInfo.wildHoldemInfo,
    }
  })

  const { virtualSportList } = useSelector(state => {
    const { virtualGameInfo } = state

    return {
      virtualSportList: virtualGameInfo.virtualSportList.toSorted((a, b) => a.sportOrderNum - b.sportOrderNum),
    }
  })

  // 파트너 추천 코드 용 쿼리 스트링
  const [recParams] = useSearchParams()

  useEffect(() => {
    const rec = recParams.get('rec')

    if (!rec) {
      return
    }
    setRecommendCode(rec)
  }, [recParams])

  useEffect(() => {
    if (!isLogin()) return

    dispatch(fetchMemberInfoAction())
    dispatch(fetchJackpot())

    const intervalId = setInterval(() => {
      dispatch(fetchMemberInfoAction())
      dispatch(fetchJackpot())
    }, 5000)

    return () => {
      clearInterval(intervalId) // 컴포넌트 언마운트 시 인터벌 정리
    }
  }, [loginCheck])

  // 로그인 모달
  const [signInModalShow, setSignInModalShow] = useState(false)
  const onCloseSignInModal = () => {
    setSignInModalShow(false)
  }

  const onClickSignOutHandler = () => {
    handleDeleteAuthentication({
      accessToken: getAccessToken(),
    }).then(res => {
      deleteAccessToken()
      window.location.href = '/'
    })
  }

  // 회원 가입 모달
  const [signUpModalShow, setSignUpModalShow] = useState(false)
  const onShowSignUpModal = () => {
    setSignUpModalShow(true)
  }
  const onCloseSignUpModal = () => {
    setSignUpModalShow(false)
  }

  const [authorityAlertShow, setAuthorityAlertShow] = useState(false)

  const onCloseAuthorityAlert = () => {
    setAuthorityAlertShow(false)
  }

  const [noteAlertShow, setNoteAlertShow] = useState(false)
  const [oneToOneAlertShow, setOneToOneAlertShow] = useState(false)

  const onClickMenuHandler = link => {
    if (!isLogin()) {
      setAuthorityAlertShow(true)
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    if (oneToOneRedisSize > 0) {
      setOneToOneAlertShow(true)
      return
    }

    navigate(link)
  }

  const onClickVirtualMenuHandler = () => {
    if (!isLogin()) {
      setAuthorityAlertShow(true)
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    if (oneToOneRedisSize > 0) {
      setOneToOneAlertShow(true)
      return
    }

    const { sportType } = virtualSportList[0] ?? ''

    switch (sportType) {
      case 'VIRTUAL_SOCCER':
        return navigate('/virtual/soccer')
      case 'VIRTUAL_BASKETBALL':
        return navigate('/virtual/basketball')
      case 'VIRTUAL_BASEBALL':
        return navigate('/virtual/baseball')
      case 'VIRTUAL_GREYHOUNDS':
        return navigate('/virtual/greyhounds')
      case 'VIRTUAL_HORSE':
        return navigate('/virtual/horse')
      default:
        return navigate('/')
    }
  }

  const onClickPartnerUrlHandler = () => {
    searchPartnerUrl().then(res => {
      const { partnerUrl } = res.data

      if (partnerUrl.includes('localhost')) {
        window.open(`http://${partnerUrl}`)
      } else {
        window.open(`https://${partnerUrl}`)
      }
    })
  }

  const changeButton = () => {
    dispatch(toggleLeftMenuSidebarShow(!isShowLeftMenuSidebar))
  }
  const [createLogLoading, setCreateLogLoading] = useState(false)

  useEffect(() => {
    if (!isLogin()) return

    if (reloadCount === 0 || reloadTime === 0) return

    const navigationEntries = performance.getEntriesByType('navigation')
    if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
      // 상태 업데이트
      const timestamps = JSON.parse(sessionStorage.getItem('timestamps') || '[]')
      timestamps.push(Date.now())

      // 관리자에서 설정한 시간 차이 ex) 1분
      const diffMinuteAgo = Date.now() - reloadTime

      const filteredTimestamps = timestamps.filter(timestamp => timestamp > diffMinuteAgo)

      // sessionStorage 업데이트
      sessionStorage.setItem('timestamps', JSON.stringify(filteredTimestamps))

      // 1분 동안의 새로고침 횟수가 10번을 초과하면 악의적인 새로고침으로 판단
      if (filteredTimestamps.length > reloadCount) {
        sessionStorage.setItem('timestamps', JSON.stringify([]))
        if (createLogLoading) return
        setCreateLogLoading(true)
        createReloadCheckLog()
          .then(res => {
            setCreateLogLoading(false)
          })
          .catch(error => {
            setCreateLogLoading(false)
          })
      }
    }
  }, [reloadCount, reloadTime]) // 의존성 배열을 빈 배열로 변경

  return (
    <HeaderContainerWrap>
      {noteAlertShow && (
        <CustomAlert
          info="읽지 않은 중요한 쪽지가 있어 이동이 불가합니다."
          onClickCloseHandler={() => {
            setNoteAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {oneToOneAlertShow && (
        <CustomAlert
          info="문의 답변건을 먼저 확인해주세요."
          onClickCloseHandler={() => {
            setOneToOneAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {authorityAlertShow && <AuthorityAlertPopup visible={authorityAlertShow} onClose={onCloseAuthorityAlert} />}
      {signInModalShow && (
        <SignInModal visible={signInModalShow} onClose={onCloseSignInModal} onShowSignUpModal={onShowSignUpModal} />
      )}
      {signUpModalShow && <SignUpModal visible={signUpModalShow} onClose={onCloseSignUpModal} />}

      {/* <HeaderBergurIconWrap>
        <CustomAiOutlineMenu />
      </HeaderBergurIconWrap> */}
      <HeaderLogoWrap>
        <AiOutlineMenu style={{ color: '#bcbdc2', cursor: 'pointer' }} size={18} onClick={changeButton} />
        <HeaderLogoInner
          onClick={e => {
            navigate('/')
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/img/main/Logo.png`} alt="" />
        </HeaderLogoInner>
      </HeaderLogoWrap>
      <HeaderMenuWrap>
        <HeaderMenuItemBox>
          {domesticSportsUsing && (
            <HeaderMenuItem
              active={location.pathname === '/sports/domestic'}
              onClick={e => {
                dispatch(setDomesticSelectedSportId(-1))
                onClickMenuHandler('/sports/domestic')
              }}
            >
              <span>{domesticSportsName}</span>
            </HeaderMenuItem>
          )}
          {overseasSportsUsing && (
            <HeaderMenuItem
              active={location.pathname === '/sports/overseas'}
              onClick={e => {
                dispatch(setOverseasSelectedSportId(-1))
                onClickMenuHandler('/sports/overseas')
              }}
            >
              <span>{overseasSportsName}</span>
            </HeaderMenuItem>
          )}
          {specialSportsUsing && (
            <HeaderMenuItem
              active={location.pathname === '/sports/special'}
              onClick={e => {
                dispatch(setSpecialSelectedSportId(-1))
                onClickMenuHandler('/sports/special')
              }}
            >
              <span>{specialSportsName}</span>
            </HeaderMenuItem>
          )}
          {liveSportsUsing && (
            <HeaderMenuItem
              active={location.pathname === '/sports/live'}
              onClick={e => {
                dispatch(setLiveSelectedSportId(-1))
                onClickMenuHandler('/sports/live')
              }}
            >
              <span>{liveSportsName}</span>
            </HeaderMenuItem>
          )}

          <HeaderMenuItem
            active={location.pathname === '/live-casino'}
            onClick={e => {
              onClickMenuHandler('/live-casino')
            }}
          >
            <span>라이브 카지노</span>
          </HeaderMenuItem>
          <HeaderMenuItem
            active={location.pathname === '/slot-casino'}
            onClick={e => {
              onClickMenuHandler('/slot-casino')
            }}
          >
            <span>슬롯 카지노</span>
          </HeaderMenuItem>
          <HeaderMenuItem
            active={location.pathname.includes('minigame')}
            onClick={e => {
              onClickMenuHandler('/minigame')
            }}
          >
            <span>미니게임</span>
          </HeaderMenuItem>
          <HeaderMenuItem
            active={location.pathname.includes('virtual')}
            onClick={e => {
              onClickVirtualMenuHandler()
            }}
          >
            <span>가상게임</span>
          </HeaderMenuItem>
          <HeaderMenuItem
            active={location.pathname.includes('holdem/revolution')}
            onClick={e => {
              onClickMenuHandler('/holdem/revolution')
            }}
          >
            <span>{revolutionHoldemInfo?.title}</span>
          </HeaderMenuItem>
          <HeaderMenuItem
            active={location.pathname.includes('holdem/wild-games')}
            onClick={e => {
              onClickMenuHandler('/holdem/wild-games')
            }}
          >
            <span>{wildHoldemInfo?.title}</span>
          </HeaderMenuItem>
          {decodeAccessToken()?.auth === 'PARTNER' && (
            <HeaderMenuItem onClick={onClickPartnerUrlHandler}>
              <span>파트너 페이지</span>
            </HeaderMenuItem>
          )}
        </HeaderMenuItemBox>
      </HeaderMenuWrap>
      <HeaderRightMenuWrap>
        <HeaderRightMenuInnerWrap>
          <HeaderRightMenuTimeWrap>
            <span style={{ fontSize: '14px' }}>
              <p>{currentTime.locale('ko').format('HH:mm:ss')}</p>
            </span>
            {/* 소리 음소거 버튼 */}
            {/* <CustomIoVolumeHighSharp /> */}
          </HeaderRightMenuTimeWrap>
          <HeaderRightMenuButtonWrap>
            {loginCheck ? (
              <HeaderRightMenuLoginButton onClick={onClickSignOutHandler}>로그아웃</HeaderRightMenuLoginButton>
            ) : (
              <>
                <HeaderRightMenuLoginButton
                  onClick={() => {
                    setSignInModalShow(true)
                  }}
                >
                  로그인
                </HeaderRightMenuLoginButton>
                <HeaderRightMenuSignUpButton
                  onClick={() => {
                    setSignUpModalShow(true)
                  }}
                >
                  회원가입
                </HeaderRightMenuSignUpButton>
              </>
            )}
          </HeaderRightMenuButtonWrap>
        </HeaderRightMenuInnerWrap>
      </HeaderRightMenuWrap>
    </HeaderContainerWrap>
  )
}

export default Header

const HeaderContainerWrap = styled.div`
  position: fixed;
  background: black;
  top: 0px;
  margin: 0 auto;
  width: 100%;
  min-width: 1260px;
  z-index: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #141517 !important;
  height: 64px;
  border-bottom: 1px solid #242424;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`

const HeaderLogoWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  float: left;
  padding: 0;
  margin: 0;
  margin-right: auto;
  margin-left: 8px;
`

const HeaderLogoInner = styled.div`
  float: left;
  padding: 0;
  margin: 0;
  font-size: 28px;
  font-weight: 600;
  margin-left: 3px;
  color: ${HermesMainText};
  img {
    width: auto;
    height: 32px;
    cursor: pointer;

    vertical-align: middle;
  }
`

const HeaderBergurIconWrap = styled.div`
  display: flex;
  float: left;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;

  align-items: center;
  height: 100%;
`

const CustomAiOutlineMenu = styled(AiOutlineMenu)`
  cursor: pointer;
  color: #b3b3b3;
  width: 18px;
  height: 18px;
`

const HeaderMenuWrap = styled.div`
  // max 1440 에서 none로 처리하던데..
  width: 1300px;
`

const HeaderMenuItemBox = styled.div`
  // width: 100%;
  display: flex;
  algin-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
`

const HeaderMenuItem = styled.div`
  height: 64px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  padding: 10px 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
  transition: border-bottom 0.1s ease-out 0s;

  // 해당 메뉴일때 아래 활성화
  ${props =>
    props.active &&
    `
    background-color: ${HermesMainBorder};
  `}

  &:hover {
    background-color: rgb(36, 42, 47);
    border-bottom: 3px solid ${HermesMainHover2};
  }

  span {
    cursor: pointer;
  }
`
const HeaderRightMenuWrap = styled.div`
  // width: 100%;
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 0 5px;
`

const HeaderRightMenuInnerWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-itmes: center;
`

const HeaderRightMenuTimeWrap = styled.div`
  color: #a0a0a0;
  padding: 1px;
  margin-top: 5px;
  margin: 0;
  display: flex;
  align-items: center;
  margin-right: 10px;
  span {
    float: left;
    margin-top: 1px;
    color: #a0a0a0;
    margin-right: 7px;
  }

  span p {
    color: #e6e6e6;
    margin: 0;
  }
`

const CustomIoVolumeHighSharp = styled(IoVolumeHighSharp)`
  width: 28px;
  height: 28px;
  color: #f56406;
  cursor: pointer;
  border: 1px solid #f56406;
  padding: 2px;
  border-radius: 50%;
`
const CustomIoVolumeMute = styled(IoVolumeMute)`
  width: 28px;
  height: 28px;
  color: #f56406;
  cursor: pointer;
  border: 1px solid #f56406;
  padding: 2px;
  border-radius: 50%;
`

const HeaderRightMenuButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const HeaderRightMenuLoginButton = styled.button`
  background: none;
  padding: 7px 20px;
  border-radius: 15px;
  outline: none;
  width: 100px;
  text-align: center;
  color: #fff;
  border: 1px solid ${HermesMainHover2};
  height: 32px;
  font-size: 12px;
  margin-right: 7px;
`

const HeaderRightMenuSignUpButton = styled.button`
  border-radius: 15px;
  outline: none;
  color: #000;
  background: ${HermesMainHover2};
  border: 1px solid ${HermesMainHover2};
  height: 32px;
  padding: 7px 20px;
  width: 100px;
  text-align: center;
`
