import { CustomSelect } from '@/shared/components/CustomSelect'
import { MobileTopWrap } from '@/shared/components/MoneyCustomElement'
import Error from '@/shared/components/form/Error'
import { FormGroupField } from '@/shared/components/form/FormElements'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { commonReg2 } from '@/utils/validate/commonValidate'
import { MoneyDepositValidate } from '@/utils/validate/moneyFormValidate'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import ReactHtmlParser from 'react-html-parser'
import { FaChevronLeft, FaTrash } from 'react-icons/fa'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'style-to-object'
import styled from 'styled-components'
import { searchMemberMoneyDeposit } from '../../api/memberHistory/memberHistoryApi'
import {
  createUserMoneyDeposit,
  searchDepositRule,
  searchMinimumAmount,
  updateMemberMoneyHistoryDelete,
  withdrawCasinoMoney,
} from '../../api/memberManage/memberManageApi'
import CustomLoading from '../../containers/CustomLoading'
import { onClickTitleHandler } from '../../redux/modalSlice'
import Portal from '../../shared/components/Portal'
import {
  MobileMoneyFormButtomInfo,
  MoneyFormInput,
  MoneyFormInputWrap,
  MoneyFormModifyButtomInfo,
} from '../../shared/components/form/newForm'
import { HermesMainBorder, HermesMainText, colorMainText } from '../../utils/palette'
import MobileMoneyDepositListData from '../mobileMyPage/mobileMoneyDepositHistory/MobileMoneyDepositListData'
import { CustomButtonInfo } from '../../shared/components/MobileMoneyCustomElement'
import { createOnetoOne } from '../../api/customCenter/customCenterApi'
import { fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import CustomAlert from '../../containers/CustomAlert'
import MobileCoinDepositModalContent from './MobileCoinDepositModalContent'

const MobileMoneyDepositForm = ({ visible, onClose }) => {
  const [myInfoLoading, setMyInfoLoading] = useState(true)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  /**
   *  회원 입금내역 조회
   */
  const [historyParams, setHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  useEffect(() => {
    setLoading(true)
    searchMemberMoneyDeposit(historyParams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [historyParams])

  const fetchSearchMemberMoneyDeposit = async () => {
    setLoading(true)
    await searchMemberMoneyDeposit(historyParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const [checkRadio, setCheckRadio] = useState([])
  const [depositListData, setDepositListData] = useState(MobileMoneyDepositListData(content, checkRadio, setCheckRadio))

  useEffect(() => {
    setDepositListData(MobileMoneyDepositListData(content, checkRadio, setCheckRadio))
  }, [content, checkRadio])

  const [rows, setData] = useState(depositListData.tableRowsData)

  useEffect(() => {
    setData(depositListData.tableRowsData)
  }, [depositListData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  const { holdingMoney, holdemMoneyAmount, totalGameMoney, userId, userName, bankName, accountNum } = useSelector(
    state => {
      const { memberInfo } = state

      return {
        holdingMoney: memberInfo.memberHoldingMoney,
        totalGameMoney: memberInfo.memberTotalGameMoney,
        holdemMoneyAmount: memberInfo.holdemMoneyAmount,
        userId: memberInfo.userId,
        userName: memberInfo.userName,
        bankName: memberInfo.bankName,
        accountNum: memberInfo.accountNum,
      }
    },
  )
  const [depositRuleContent, setDepositRuleContent] = useState('')
  const [isShowDepositRuleContent, setIsShowDepositRuleContent] = useState(false)

  const [depositBonusList, setDepositBonusList] = useState([])
  const [isShowDepositBonusMainSelectDrop, setIsShowDepositBonusMainSelectDrop] = useState(false)

  // 선택한 보너스 타입
  const [selectedBonusType, setSelectedBonusType] = useState(null)

  const onClickDepositBonusTypeHandler = key => {
    const data = depositBonusList.find(item => item.moneyDepositBonusKey === key)
    if (data) {
      setSelectedBonusType({
        moneyDepositBonusKey: data.moneyDepositBonusKey,
        title: data.title,
        bonusType: data.bonusType,
      })
    }
    setIsShowDepositBonusMainSelectDrop(false)
  }

  const [moneySortRadio, setMoneySortRadio] = useState('NORMAL_MONEY') // 초기값 설정

  const handleChange = event => {
    setMoneySortRadio(event.target.value) // 선택된 값 업데이트
    setSelectedBonusType(null)
    setIsShowDepositBonusMainSelectDrop(false)
  }

  // V3
  const onClickDepositBonusTypeHandlerV3 = depositBonusItem => {
    setSelectedBonusType({
      moneyDepositBonusKey: depositBonusItem.moneyDepositBonusKey,
      bonusTitle: depositBonusItem.bonusTitle,
      bonusContent: depositBonusItem.bonusContent,
      bonusType: depositBonusItem.bonusType,
    })

    setIsShowDepositBonusMainSelectDrop(false)
  }

  const getDepositBonusImageUrl = type => {
    // hover 때문에 확장자 뺌
    switch (type) {
      case 'BONUS_NONE':
        return `${process.env.PUBLIC_URL}/img/main/bonus_sports_etc`
      case 'BONUS_SPORTS':
      case 'BONUS_SPORTS_NEW':
      case 'BONUS_PLUS':
        return `${process.env.PUBLIC_URL}/img/main/bonus_sports`
      case 'BONUS_CASINO':
      case 'BONUS_CASINO_NEW':
        return `${process.env.PUBLIC_URL}/img/main/bonus_casino`
      case 'BONUS_SLOT_CASINO':
      case 'BONUS_SLOT_CASINO_NEW':
        return `${process.env.PUBLIC_URL}/img/main/bonus_slot`
      case 'BONUS_MINI_GAME':
      case 'BONUS_MINI_GAME_NEW':
        return `${process.env.PUBLIC_URL}/img/main/bonus_mini`
      case 'BONUS_VIRTUAL_GAME':
      case 'BONUS_VIRTUAL_GAME_NEW':
        return `${process.env.PUBLIC_URL}/img/main/bonus_virtual`
      case 'BONUS_HOLDEM':
      case 'BONUS_HOLDEM_NEW':
        return `${process.env.PUBLIC_URL}/img/main/bonus_holdem`
      default:
        return ''
    }
  }

  const getSelectedBonusType = () => {
    switch (selectedBonusType?.bonusType) {
      case 'BONUS_NONE':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_SPORTS_NEW':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_SPORTS':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_CASINO':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_CASINO_NEW':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_SLOT_CASINO':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_SLOT_CASINO_NEW':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_PLUS':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_MINI_GAME':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_MINI_GAME_NEW':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_HOLDEM':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_HOLDEM_NEW':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_VIRTUAL_GAME':
        return <span>{selectedBonusType?.title}</span>
      case 'BONUS_VIRTUAL_GAME_NEW':
        return <span>{selectedBonusType?.title}</span>
      default:
        return <span style={{ color: '#fff' }}>입금 보너스 선택하기</span>
    }
  }

  const fetchSearchDepositRule = async () => {
    await searchDepositRule()
      .then(response => {
        setDepositRuleContent(response.data.depositRule)

        const filteredList = response.data.depositBonusList.filter(item => {
          if (moneySortRadio === 'NORMAL_MONEY') {
            return !item.bonusType.includes('BONUS_HOLDEM') // HOLDEM이 포함되지 않은 항목
          }
          return item.bonusType.includes('BONUS_HOLDEM') // HOLDEM이 포함된 항목
        })

        setDepositBonusList(filteredList)
      })
      .catch(error => {})
  }

  useEffect(() => {
    fetchSearchDepositRule()
  }, [moneySortRadio])

  // 최소 입금액
  const [minimumDeposit, setMinimumDeposit] = useState('0')
  const [depositMaxAmount, setDepositMaxAmount] = useState('0')

  useEffect(() => {
    searchMinimumAmount()
      .then(res => {
        setMinimumDeposit(res.data.minimumDeposit)
        setDepositMaxAmount(res.data.depositMaxAmount)
        setMyInfoLoading(false)
      })
      .catch(error => {
        setMyInfoLoading(false)
      })
  }, [])
  // 신청금액
  const [applyMoney, setApplyMoney] = useState('0')

  // 실입금액
  const [realMoney, setRealMoney] = useState('0')

  const onClickMoneyHandler = name => {
    setApplyMoney(prev => (Number(prev.replaceAll(',', '')) + Number(name)).toString().replace(commonReg2, ','))
  }

  const onClearClickHandler = () => {
    setApplyMoney('0')
  }

  const checkNumber = checkMoney => {
    return Math.sign(checkMoney)
  }

  const [inquiryButton, setInquiryButton] = useState(false)

  // api 여러번 호출 막기
  const [apiFlag, setApiFlag] = useState(false)
  const onSubmit = e => {
    if (apiFlag) return
    const checkMoney = applyMoney?.toString().replaceAll(',', '')

    if (!selectedBonusType) {
      alert('입금 보너스를 선택해주세요')
      return
    }

    if (Number(checkMoney) <= 0) {
      alert('금액을 다시 확인해주세요.')
      return
    }

    if (Number(checkMoney) > 0 && checkMoney?.substr(checkMoney.length - 3, 3) !== '000') {
      alert('1,000원 단위부터 입력이 가능합니다.')
      return
    }

    if (Number(checkMoney) > Number(depositMaxAmount)) {
      alert(`최대 ${depositMaxAmount?.toString().replace(commonReg2, ',')}원 이하로 입금 가능합니다.`)
      return
    }
    setApiFlag(true)

    const body = {
      moneySort: moneySortRadio,
      moneyType: 'DEPOSIT',
      applyMoney: checkMoney,
      moneyDepositBonusKey: selectedBonusType?.moneyDepositBonusKey,
      bonusTitle: selectedBonusType?.bonusTitle || '',
      bonusContent: selectedBonusType?.bonusContent || '',
    }
    setLoading(true)
    createUserMoneyDeposit(body)
      .then(res => {
        setLoading(false)
        alert('입금신청이 완료되었습니다.')
        setApiFlag(false)
        fetchSearchDepositRule()
        fetchSearchMemberMoneyDeposit()
        onClearClickHandler()
      })
      .catch(error => {
        const { errorCode, message } = error.response.data
        setLoading(false)
        switch (errorCode) {
          case 'MONEY-1007':
            alert('현재 처리중인 입금 신청이 존재합니다.\n입금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1008':
            alert('현재 처리중인 출금 신청이 존재합니다.\n출금내역 탭에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1009':
            alert('현재 머니이동을 처리중입니다.\n잠시 후 다시 시도해주세요.')
            break
          case 'ASSET-1010':
            alert('입금이 불가능한 시간입니다.')
            break
          case 'ASSET-1004':
            alert(message)
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('입금신청이 실패했습니다. 다시 시도해주세요.')
        }
        fetchSearchMemberMoneyDeposit()
        fetchSearchDepositRule()
        setApiFlag(false)
      })
  }
  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e)
    }
  }

  const close = e => {
    if (onClose) {
      onClose(e)
    }
  }
  const [selectedIndex, setSelectedIndex] = useState(0)

  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={{ maxWidth: '100%' }} alt="" />
    }
  }

  const [deleteLoading, setDeleteLoading] = useState(false)
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 목록을 선택해주세요.')
      return
    }

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      updateMemberMoneyHistoryDelete({
        moneyType: 'DEPOSIT',
        moneyHistoryKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchMemberMoneyDeposit()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제 실패했습니다.')
          setCheckRadio([])
          fetchSearchMemberMoneyDeposit()
        })
    }
  }

  const [questionLoading, setQuestionLoading] = useState(false)
  const onClickMenuModalHandler = title => {
    dispatch(onClickTitleHandler(title))
  }

  const onQuestionAccountHandler = () => {
    if (questionLoading) return
    const body = {
      questionTitle: '❤️ 자동 계좌문의',
      questionContent: '계좌문의 드립니다.',
    }
    setQuestionLoading(true)
    createOnetoOne(body)
      .then(res => {
        alert('계좌문의가 접수되었습니다.')
        setQuestionLoading(false)
        onClickMenuModalHandler('ONETOONE')
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'CUS-1015':
            alert(message)
            break
          default:
            alert('1:1문의 접수가 실패했습니다. 다시 시도해주세요.')
        }
        setQuestionLoading(false)
      })
  }

  const [alertPopupShow, setAlertPopupShow] = useState(false)
  // 경고창 용 팝업
  const [alertPopupMessage, setAlertPopupMessage] = useState('')

  const onCloseAlertPopup = () => {
    setAlertPopupShow(false)
    setAlertPopupMessage('')
  }

  const [moneyExchangeLoading, setMoneyExchangeLoading] = useState(false)

  const onChangeAlertPopupMessageHandler = text => {
    setAlertPopupShow(true)
    setAlertPopupMessage(text)
  }

  const onClickCasinoMoneyWithdrawHandler = () => {
    if (moneyExchangeLoading) {
      onChangeAlertPopupMessageHandler('카지노 머니 회수 진행중입니다. 잠시만 기다려주세요.')
      return
    }
    if (totalGameMoney <= 0) return

    setMoneyExchangeLoading(true)

    withdrawCasinoMoney()
      .then(res => {
        dispatch(fetchMemberInfoAction())
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'GAME-1014':
            onChangeAlertPopupMessageHandler('카지노 머니 동기화중입니다. \n잠시후에 다시 시도해주세요.')
            break
          case 'ASSET-7001':
            onChangeAlertPopupMessageHandler('잠시후 다시 시도해주세요.')
            break
          default:
            onChangeAlertPopupMessageHandler('처리 실패했습니다.')
        }
      })
      .finally(() => {
        setMoneyExchangeLoading(false)
      })
  }

  const [coinModalShow, setCoinModalShow] = useState(false)

  const onClickCoinModalHandler = () => {
    const checkMoney = applyMoney?.toString().replaceAll(',', '')

    if (!selectedBonusType) {
      alert('입금보너스를 선택해주세요.')
      return
    }

    if (Number(checkMoney) <= 0) {
      alert('금액을 다시 확인해주세요.')
      return
    }

    if (Number(checkMoney) > 0 && checkMoney?.substr(checkMoney.length - 4, 4) !== '0000') {
      alert('10,000원 단위로 입력이 가능합니다.')
      return
    }

    if (Number(checkMoney) < Number(minimumDeposit)) {
      alert(`최소 ${minimumDeposit?.toString().replace(commonReg2, ',')}원 이상 입금 가능합니다.`)
      return
    }

    if (Number(checkMoney) > Number(depositMaxAmount)) {
      alert(`최대 ${depositMaxAmount?.toString().replace(commonReg2, ',')}원 이하로 입금 가능합니다.`)
      return
    }

    setCoinModalShow(true)
  }

  const onCloseCoinModalHandler = () => {
    setCoinModalShow(false)
    fetchSearchDepositRule()
    fetchSearchMemberMoneyDeposit()
    setSelectedBonusType(null)
    setApplyMoney('')
  }

  return (
    <Portal elementId="signup-modal">
      {alertPopupShow && <CustomAlert info={alertPopupMessage} onClickCloseHandler={onCloseAlertPopup} />}
      {coinModalShow && (
        <Portal elementId="signup-modal">
          <SignUpModalOverlay visible={coinModalShow} />
          <SignUpModalWrapper
            onClick={e => {
              if (e.target === e.currentTarget) {
                onCloseCoinModalHandler(e)
              }
            }}
            tabIndex="-1"
            visible={coinModalShow}
            className="sidebar-container"
          >
            <SignUpModalInner tabIndex="0">
              <SignUpModalHeader>
                <SignUpModalCloseButton
                  onClick={onCloseCoinModalHandler}
                  src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
                  alt=""
                />
              </SignUpModalHeader>
              <SignUpModalContentWrap>
                <MobileCoinDepositModalContent
                  applyDepositInfo={{
                    moneyType: 'DEPOSIT',
                    applyMoney: applyMoney?.toString().replaceAll(',', ''),
                    moneyDepositBonusKey: selectedBonusType?.moneyDepositBonusKey,
                    bonusTitle: selectedBonusType?.bonusTitle || '',
                    bonusContent: selectedBonusType?.bonusContent || '',
                    moneySort: moneySortRadio,
                    minimumDeposit,
                    depositMaxAmount,
                  }}
                  onCloseCoinModalHandler={onCloseCoinModalHandler}
                />
              </SignUpModalContentWrap>
            </SignUpModalInner>
          </SignUpModalWrapper>
        </Portal>
      )}
      <SignUpModalOverlay visible={visible} />
      <SignUpModalWrapper onClick={onMaskClick} tabIndex="-1" visible={visible}>
        <SignUpModalInner tabIndex="0">
          <SignUpModalHeader>
            <SignUpModalHeaderLogo src={`${process.env.PUBLIC_URL}/img/main/Logo.png`} alt="" />
            <SignUpModalCloseButton
              onClick={close}
              src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
              alt=""
            />
          </SignUpModalHeader>
          <SignUpModalContentWrap>
            <MobileTopWrap>
              {myInfoLoading || loading || moneyExchangeLoading ? (
                <CustomLoading />
              ) : (
                <>
                  <ContentWrap>
                    <ContentRowWrap>
                      <FaChevronLeft
                        onClick={e => {
                          dispatch(onClickTitleHandler('MYPAGE'))
                        }}
                      />
                      <ContentRowSmall>
                        <div>
                          <span style={{ color: '#b2b2b2' }}>보유머니</span>
                          <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
                            {holdingMoney?.toString().replace(commonReg2, ',')} 원
                          </span>
                        </div>
                      </ContentRowSmall>
                      <ContentRowSmall>
                        <div>
                          <span style={{ color: '#b2b2b2' }}>홀덤머니</span>
                          <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
                            {holdemMoneyAmount?.toString().replace(commonReg2, ',')} 원
                          </span>
                        </div>
                      </ContentRowSmall>
                      <ContentRowSmall>
                        <div>
                          <span style={{ color: '#b2b2b2' }}>카지노 캐쉬</span>
                          <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
                            {totalGameMoney?.toString().replace(commonReg2, ',')} 원
                          </span>
                        </div>
                      </ContentRowSmall>
                    </ContentRowWrap>
                    <ContentRowThirdWrap>
                      <ContentRowBig selectedIndex={selectedIndex}>
                        <div onClick={() => setSelectedIndex(0)}>입금</div>
                        <div onClick={() => setSelectedIndex(1)}>입금내역</div>
                        <div onClick={() => setSelectedIndex(2)}>입금규정</div>
                      </ContentRowBig>
                    </ContentRowThirdWrap>
                    <ContentInfo>
                      {
                        // 여기서 구분
                      }
                      {selectedIndex === 0 && (
                        <Form
                          onSubmit={onSubmit}
                          initialValues={{
                            memberId: userId,
                            memberName: userName,
                            bankName,
                            accountHolder: accountNum,
                            applyMoney,
                            holdingMoney,
                            minimumDeposit,
                            depositMaxAmount,
                          }}
                          validate={MoneyDepositValidate}
                        >
                          {({ handleSubmit, form: { reset } }) => (
                            <div onSubmit={handleSubmit}>
                              <ContentRowSecondWrap>
                                <div
                                  style={{
                                    fontWeight: '700',
                                    display: 'flex',
                                    gap: '10px',
                                    width: '100%',
                                    padding: '0 0 24px 24px',
                                    fontSize: '12px',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div
                                    style={{
                                      fontWeight: '700',
                                      display: 'flex',
                                      gap: '5px',
                                      width: '100%',
                                      fontSize: '12px',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      id="NORMAL_MONEY"
                                      name="moneySort"
                                      value="NORMAL_MONEY"
                                      checked={moneySortRadio === 'NORMAL_MONEY'}
                                      onChange={handleChange}
                                    />
                                    <label htmlFor="NORMAL_MONEY" style={{ cursor: 'pointer', marginRight: '10px' }}>
                                      보유머니 입금
                                    </label>
                                    <input
                                      type="radio"
                                      id="HOLDEM_MONEY"
                                      name="moneySort"
                                      value="HOLDEM_MONEY"
                                      checked={moneySortRadio === 'HOLDEM_MONEY'}
                                      onChange={handleChange}
                                    />
                                    <label htmlFor="HOLDEM_MONEY" style={{ cursor: 'pointer' }}>
                                      홀덤머니 입금
                                    </label>
                                  </div>
                                </div>
                                <span style={{ fontWeight: '500', marginBottom: '30px' }}>
                                  <span style={{ color: `${HermesMainText}` }}>
                                    ※ 주의! 반드시 종목에 맞게 입금 보너스 선택 후 입금 신청 바랍니다
                                  </span>
                                </span>
                                <CustomDeleteButton2
                                  onClick={() => onQuestionAccountHandler()}
                                  variant="primary"
                                  type="button"
                                >
                                  <CustomButtonInfos2>계좌문의</CustomButtonInfos2>
                                </CustomDeleteButton2>
                                <DepositBonusSelectWrap>
                                  <DepositBonusSelectInnerWrap>
                                    <DepositBonusMainSelectWrap
                                      onClick={() => {
                                        setIsShowDepositBonusMainSelectDrop(prev => !prev)
                                      }}
                                    >
                                      {selectedBonusType?.bonusTitle || '입금 보너스 선택하기'}
                                      <CustomMdKeyboardArrowDown />
                                    </DepositBonusMainSelectWrap>
                                    {isShowDepositBonusMainSelectDrop && (
                                      <DepositBonusMainSelectDropWrap>
                                        {/* 아래부터 반복 */}
                                        {depositBonusList.map(item => (
                                          <DepositBonusMainSelectDropItemWrap>
                                            <DepositBonusMainSelectDropItemBox
                                              url={getDepositBonusImageUrl(item.bonusType)}
                                              onClick={() => {
                                                onClickDepositBonusTypeHandlerV3(item)
                                              }}
                                            >
                                              <DepositBonusMainSelectDropItemImg />
                                              <DepositBonusMainSelectDropItemTextBox sc={item.bonusContent}>
                                                <DepositBonusMainSelectDropItemTextContent>
                                                  {item.bonusTitle}
                                                </DepositBonusMainSelectDropItemTextContent>
                                                {item.bonusContent && (
                                                  <DepositBonusMainSelectDropItemTextSubContent>
                                                    {item.bonusContent}
                                                  </DepositBonusMainSelectDropItemTextSubContent>
                                                )}
                                              </DepositBonusMainSelectDropItemTextBox>
                                            </DepositBonusMainSelectDropItemBox>
                                          </DepositBonusMainSelectDropItemWrap>
                                        ))}
                                      </DepositBonusMainSelectDropWrap>
                                    )}
                                  </DepositBonusSelectInnerWrap>
                                </DepositBonusSelectWrap>
                                <div
                                  style={{
                                    color: '#fff',
                                    marginTop: '16px',
                                    width: '100%',
                                    padding: '0 16px',
                                    fontWeight: '500',
                                    fontSize: '13px',
                                  }}
                                >
                                  <p style={{ wordBreak: 'keep-all', color: `${HermesMainText}` }}>
                                    ● 당사에 등록하신 회원님 환전 계좌에서만 입금이 가능합니다.
                                  </p>
                                  <p style={{ wordBreak: 'keep-all', color: `${HermesMainText}` }}>
                                    ● 반드시 선입금 후 입금 신청하시기 바랍니다
                                  </p>
                                </div>
                                <CustomerCenterFormGroup style={{ marginBottom: '30px' }}>
                                  <CustomerCenterFormLabel>
                                    입금신청 금액
                                    <div
                                      style={{ marginTop: '5px', width: '100%', fontSize: '12px', fontWeight: '400' }}
                                    >
                                      <p>
                                        * 입금 최소액은{' '}
                                        <span style={{ color: `${HermesMainText}` }}>
                                          {' '}
                                          {minimumDeposit?.toString().replace(commonReg2, ',') || 0}원
                                        </span>
                                        <br /> &nbsp;1회 입금 최대액
                                        <span style={{ color: `${HermesMainText}` }}> (레벨별 상이)</span>은{' '}
                                        <span style={{ color: `${HermesMainText}` }}>
                                          {' '}
                                          {depositMaxAmount?.toString().replace(commonReg2, ',') || 0}원
                                        </span>{' '}
                                        입니다.
                                      </p>
                                    </div>
                                  </CustomerCenterFormLabel>
                                  <CustomCenterFormField>
                                    <Field name="applyMoney">
                                      {({ input, meta }) => (
                                        <MoneyFormInputWrap>
                                          <MoneyFormInput
                                            {...input}
                                            type="text"
                                            value={input.value}
                                            onChange={e => {
                                              input.onChange(e)
                                              setApplyMoney(e.target.value)
                                            }}
                                          />
                                          {meta.error && <Error error={meta.error} isMobile />}
                                        </MoneyFormInputWrap>
                                      )}
                                    </Field>
                                  </CustomCenterFormField>
                                </CustomerCenterFormGroup>
                                <CustomerCenterFormGroup>
                                  <CustomCenterFormField>
                                    <MoneyFormButton
                                      size="sm"
                                      type="button"
                                      variant="outline-secondary"
                                      name="10000"
                                      onClick={e => {
                                        onClickMoneyHandler('10000')
                                      }}
                                    >
                                      <MobileMoneyFormButtomInfo>1만원</MobileMoneyFormButtomInfo>
                                    </MoneyFormButton>
                                    <MoneyFormButton
                                      size="sm"
                                      type="button"
                                      variant="outline-secondary"
                                      name="50000"
                                      onClick={e => {
                                        onClickMoneyHandler('50000')
                                      }}
                                    >
                                      <MobileMoneyFormButtomInfo>5만원</MobileMoneyFormButtomInfo>
                                    </MoneyFormButton>
                                    <MoneyFormButton
                                      size="sm"
                                      type="button"
                                      variant="outline-secondary"
                                      name="100000"
                                      onClick={e => {
                                        onClickMoneyHandler('100000')
                                      }}
                                    >
                                      <MobileMoneyFormButtomInfo>10만원</MobileMoneyFormButtomInfo>
                                    </MoneyFormButton>
                                    <MoneyFormButton
                                      size="sm"
                                      type="button"
                                      variant="outline-secondary"
                                      name="500000"
                                      onClick={e => {
                                        onClickMoneyHandler('500000')
                                      }}
                                    >
                                      <MobileMoneyFormButtomInfo>50만원</MobileMoneyFormButtomInfo>
                                    </MoneyFormButton>
                                  </CustomCenterFormField>
                                </CustomerCenterFormGroup>
                                <CustomerCenterFormGroup>
                                  <CustomCenterFormField>
                                    <MoneyFormButton
                                      size="sm"
                                      type="button"
                                      variant="outline-secondary"
                                      name="1000000"
                                      onClick={e => {
                                        onClickMoneyHandler('1000000')
                                      }}
                                    >
                                      <MobileMoneyFormButtomInfo>100만원</MobileMoneyFormButtomInfo>
                                    </MoneyFormButton>
                                    <MoneyFormButton
                                      size="sm"
                                      type="button"
                                      variant="outline-secondary"
                                      name="3000000"
                                      onClick={e => {
                                        onClickMoneyHandler('3000000')
                                      }}
                                    >
                                      <MobileMoneyFormButtomInfo>300만원</MobileMoneyFormButtomInfo>
                                    </MoneyFormButton>
                                    <MoneyFormButton
                                      size="sm"
                                      type="button"
                                      variant="outline-secondary"
                                      name="10000000"
                                      onClick={e => {
                                        onClickMoneyHandler('10000000')
                                      }}
                                    >
                                      <MobileMoneyFormButtomInfo>1000만원</MobileMoneyFormButtomInfo>
                                    </MoneyFormButton>
                                    <MoneyFormButton
                                      size="sm"
                                      type="button"
                                      variant="outline-secondary"
                                      name="0"
                                      onClick={e => {
                                        onClearClickHandler()
                                      }}
                                    >
                                      <MobileMoneyFormButtomInfo>
                                        <FaTrash />
                                      </MobileMoneyFormButtomInfo>
                                    </MoneyFormButton>
                                  </CustomCenterFormField>
                                </CustomerCenterFormGroup>
                                <div style={{ width: '100%', display: 'flex', gap: '10px', justifyContent: 'center' }}>
                                  <MoneyButton variant="primary" type="submit" onClick={onSubmit}>
                                    <CustomMoneyFormModifyButtomInfo>원화 입금신청</CustomMoneyFormModifyButtomInfo>
                                  </MoneyButton>
                                  <MoneyButton
                                    style={{ background: 'darkorange', border: 'solid 1px darkorange' }}
                                    variant="primary"
                                    type="submit"
                                    onClick={onClickCoinModalHandler}
                                  >
                                    <CustomMoneyFormModifyButtomInfo>테더 입금신청</CustomMoneyFormModifyButtomInfo>
                                  </MoneyButton>
                                </div>
                              </ContentRowSecondWrap>
                            </div>
                          )}
                        </Form>
                      )}
                      {selectedIndex === 1 && (
                        <MoneyDepositBodyTwoWrap>
                          <ContentRowWrap2>
                            <CustomButtonWrap>
                              <CustomDeleteButton
                                onClick={() => {
                                  setCheckRadio(content.map(value => value.moneyDepositKey))
                                }}
                                type="button"
                              >
                                <CustomButtonInfos>전체선택</CustomButtonInfos>
                              </CustomDeleteButton>
                              <CustomDeleteButton
                                onClick={() => {
                                  onDeleteHandler()
                                }}
                                variant="primary"
                                type="button"
                              >
                                <FaTrash />
                                <CustomButtonInfos>삭제</CustomButtonInfos>
                              </CustomDeleteButton>
                            </CustomButtonWrap>
                          </ContentRowWrap2>
                          <ReactTableBase
                            columns={depositListData.tableHeaderData}
                            data={rows}
                            tableConfig={tableConfig}
                          />
                        </MoneyDepositBodyTwoWrap>
                      )}
                      {selectedIndex === 2 && (
                        <MoneyDepositRuleWrap>
                          <MoneyDepositRuleContentWrap>
                            {ReactHtmlParser(depositRuleContent, { transform: transformFn })}
                          </MoneyDepositRuleContentWrap>
                        </MoneyDepositRuleWrap>
                      )}
                    </ContentInfo>
                  </ContentWrap>
                </>
              )}
            </MobileTopWrap>
          </SignUpModalContentWrap>
        </SignUpModalInner>
      </SignUpModalWrapper>
    </Portal>
  )
}

export default MobileMoneyDepositForm

export const CustomButtonInfos2 = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  text-align: center;

  color: white !important;
`

const CustomDeleteButton2 = styled.button`
  border: none;
  background-color: ${HermesMainBorder};
  border-radius: 3px;
  display: flex;
  width: 80px;
  padding: 0px 8px;
  height: 30px;
  position: relative;
  right: 0%;
  margin-top: -30px;
  margin-bottom: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;
  svg {
    color: grey;
  }
`

export const MobileListButtonWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: auto;
  padding-left: 5px;
  gap: 5px;
`

export const CustomButtonWrap = styled(MobileListButtonWrap)`
  padding: 0px 10px;
`

export const CustomButtonInfos = styled(CustomButtonInfo)``

export const CustomDeleteButton = styled.button`
  background-color rgb(44, 52, 59);
  border: none;
  border-radius: 3px;
  display: flex;
  width: max-content;
  min-width: 50px;
  padding: 0px 5px;
  height: 42px;
  text-align:center;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 5px;
    color: grey;
  }
`

const ContentInfo = styled.div`
  width: 100%;
`

const CustomMoneyFormModifyButtomInfo = styled(MoneyFormModifyButtomInfo)`
  font-size: 14px;
  font-weight: 700;
`
const MoneyButton = styled.button`
  width: 150px;
  margin-top: 30px;
  height: 45px;
  background: ${HermesMainBorder};
  border-radius: 6px !important;
  font-size: 12px;
  outline: none;
  color: #fff;
  font-weight: 300;
  border: 2px solid ${HermesMainBorder};
`

export const MoneyFormButton = styled.button`
  width: 100px;
  height: 35px;
  border: none;
  border-radius: 2px;
  background: #343438;
`

const CustomCenterFormField = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
`

const CustomerCenterFormGroup = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
`

const CustomerCenterFormLabel = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  text-align: left;
`

const SignUpButton = styled.button`
  width: 100%;
  margin-top: 30px;
  height: 45px;
  background: none;
  border-radius: 6px !important;
  font-size: 12px;
  outline: none;
  color: #fff;
  font-weight: 300;
  border: 2px solid ${HermesMainBorder};
`

const ContentWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0px;
`

const ContentRowWrap2 = styled.div`
  border-bottom: solid 1px #252525;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 42px;
  svg {
    width: 20px;
    height: 20px;
    color: #fff;
    margin-right: 20px;
  }
`

const ContentRowWrap = styled.div`
  border-bottom: solid 1px #252525;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 62px;
  margin-top: 15px;
  padding-left: 25px;
  svg {
    width: 20px;
    height: 20px;
    color: #fff;
    margin-right: 20px;
  }
`
const ContentRowThirdWrap = styled.div`
  border-bottom: solid 1px #252525;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 62px; /* 혹은 다른 높이 값으로 설정 */
`

const ContentRowSecondWrap = styled.div`
  margin-top: 30px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border: solid 1px red;
  span {
    color: #fff;
  }
`

const ContentRowSelectWrap = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  background: #202024 !important;
  padding: 10px 5px;
`
const ContentRowSmall = styled.div`
  width: 50%;
  color: #fff;
  display: flex;
  align-items: center; /* Optional: to align items vertically in the center */
  justify-content: space-between;
  padding-left: 20px;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
    font-size: 12px;
    font-weight: 500;
  }
  & > img {
    margin-left: auto;
  }
`

const ContentRowBig = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    flex: 1;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    font-weight: 700 !important;
    font-family: 'Malgun Gothic', dotum;
    height: 62px;
    width: 80px;
    &:not(:last-child) {
      margin-right: 10px;
    }

    ${({ selectedIndex }) =>
      selectedIndex !== null &&
      `
      &:nth-child(${selectedIndex + 1}) {
        border-bottom: solid 1px ${HermesMainBorder};
      }
    `}
  }
`

const SignUpModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden !important;
  outline: 0;
`
const SignUpModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  width: 100%;
`

const SignUpModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  width: 100%;
  background: #16171a !important;
  height: 100%;
  margin: 0 auto;
  overflow: auto;
`

const SignUpModalHeader = styled.div`
  position: fixed;
  background: #16171a !important;
  top: 0;
  // border 양옆 1px 생각해서
  width: calc(100% - 2px);
  background-color: black;

  height: 60px;
  // border-bottom: 1px solid #7b808c;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 980;
`

// 로고
const SignUpModalHeaderLogo = styled.img`
  width: auto;
  height: 45px;
`

const LevelLogo = styled.img`
  width: 50px;
  height: 50px;
`

// 닫기 아이콘
const SignUpModalCloseButton = styled.img`
  width: 25px;
  height: 25px;

  position: absolute;
  right: 16px;

  &:hover {
    cursor: pointer;
  }
`

// modal body content
const SignUpModalContentWrap = styled.div`
  width: 100%;
  display: flex;
  // background: #16171a !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  // text-align: center;
  position: absolute;
  top: 60px;
  padding-bottom: 5px;
  // font-weight: 300;
`

const CustomDiv = styled.div`
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 12px;
`

const CustomFormGroupWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #fff;
  font-size: 12px;
`
const CustomFormGroupField = styled(FormGroupField)``
const MoneyDepositHeaderThirdInfo = styled.span`
  font-size: 12px;
  line-height: 23px;
  // color: #ffc01a;
`

const MoneyDepositHeaderTwoWrap = styled.div`
  height: 50px;
  width: 100%;
  padding: 20px 0 10px 0px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px !important;

  border-bottom: 1px solid #edae07;
`

const MoneyDepositBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 10px 0px;

  gap: 10px;

  .react-table__column-header {
    font-size: 12px;
    font-weight: 700;
    padding: 16px 0;
  }

  thead th {
    padding: 0;
  }

  tbody tr td {
    font-family: 'Malgun Gothic', dotum;
    padding: 14px 0;
    font-size: 12px;
    font-weight: 700;
  }
`

const MemberCouponSelect = styled(CustomSelect)`
  width: 100%;
  .react-select__menu {
    z-index: 101;
  }
  color: black;
  .react-select__menu-list {
    overflow: auto;
  }
`

const MoneyDepositWrap = styled.div`
  background: #000000;
  border: 1px solid #4a4a4a;

  height: 100%;
  min-height: 550px;

  position: relative;
  top: 30px;
`

const MoneyDepositHeaderWrap = styled.div`
  height: 130px;

  padding: 0 0 0 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7px;

  border-bottom: 1px solid #edae07;
`

const MoneyDepositHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  margin-bottom: 10px;
  /* 엘로우폰트 */

  color: #ffc01a;
`
export const MoneyDepositCardSubhead = styled.p`
  text-transform: none;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  opacity: 0.7;
  // margin-top: 3px;
  // margin-bottom: 5px;
  color: ${colorMainText};
`

const MoneyDepositBodyWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  // justify-content: center;

  padding: 10px 2px;

  gap: 10px;
`

export const MoneyDepositInfoWrap = styled.div`
  margin: 5px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DepositBonusSelectWrap = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: block;
  color: #fff;
  font-family: 'Malgun Gothic', dotum;
  font-size: 16px;
  word-break: break-all;
  border: solid 1px ${HermesMainBorder};
`

const DepositBonusSelectInnerWrap = styled.div`
  width: 100%;
  float: left;
  box-sizing: border-box;
  display: block;
`

const DepositBonusMainSelectWrap = styled.div`
  width: 100%;
  background: #202024 !important;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  padding: 12px !important;
  box-sizing: border-box;
  display: block;

  span {
    cursor: pointer;
  }
`

const CustomMdKeyboardArrowDown = styled(MdKeyboardArrowDown)`
  color: ${HermesMainText};
  width: auto;
  height: 25px;
  float: right;
  cursor: pointer;
`

const DepositBonusMainSelectDropWrap = styled.div`
  background: #19191c;
  position: absolute;
  width: 100%;
  z-index: 1000;
  font-size: 16px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-sizing: border-box;
  display: block;
`

const DepositBonusMainSelectDropItemWrap = styled.div`
  padding: 5px;
  box-sizing: border-box;
  position: relative;
  display: block;
`

const DepositBonusMainSelectDropItemImg = styled.div`
  background-position: 50%;
  background-size: 100% 100%;
  width: 26px;
  height: 26px;
  float: left;
  box-sizing: border-box;
  display: block;
`

const DepositBonusMainSelectDropItemBox = styled.div`
  background-color: #121212;
  padding: 10px;
  border-radius: 3px;
  display: inline-block;
  width: 100%;
  cursor: pointer;

  ${DepositBonusMainSelectDropItemImg} {
    background-image: ${props => props.url && `url(${props.url}.png)`};
  }

  &:hover {
    background-color: ${HermesMainBorder};

    ${DepositBonusMainSelectDropItemImg} {
      background-image: ${props => props.url && `url(${props.url}_hover.png)`};
    }
  }
`

const DepositBonusMainSelectDropItemTextBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${props => (props.sc ? 'column' : 'row')};
  float: left;
  padding-left: 5px;
  height: 26px;
  box-sizing: border-box;
`

const DepositBonusMainSelectDropItemTextContent = styled.div`
  width: 100%;
  text-align: left;
  box-sizing: border-box;
  position: relative;
  display: block;
  font-size: 11px;
`
const DepositBonusMainSelectDropItemTextSubContent = styled.div`
  width: 100%;
  text-align: left;
  font-size: 11px;
  box-sizing: border-box;
  position: relative;
  display: block;
`

const MoneyDepositRuleWrap = styled.div`
  background: #141517 !important;
  padding: 24px;
  box-sizing: border-box;
  display: block;
  font-family: 'Malgun Gothic', dotum;
  color: rgb(255, 255, 255);
  font-size: 13px;
`

const MoneyDepositRuleContentWrap = styled.div`
  word-break: keep-all;
`
