import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import CustomLoading from '../../../containers/CustomLoading'
import { updateSpecialFixtureCompleteAction } from '../../../redux/sportsSpecialInfoSlice'
import MobileScrollButton from '../../MobileLayout/MobileScrollButton'
import MobileSportsBettingCartSidebar from '../../MobileLayout/MobileSportsBettingCartSidebar'
import MobileSportsSpecialContent from './MobileSportsSpecialContent'
import MobileSportsSpecialHeader from './MobileSportsSpecialHeader'

const MobileSportsSpecialPage = () => {
  const dispatch = useDispatch()

  const { specialLoading, bettingCartLoading } = useSelector(state => {
    const { sportsSpecialInfo, sportsBetting } = state

    return {
      specialLoading: sportsSpecialInfo.specialLoading,
      bettingCartLoading: sportsBetting.bettingCartLoading,
    }
  })

  useEffect(() => {
    return () => {
      dispatch(updateSpecialFixtureCompleteAction())
    }
  }, [])

  return (
    <>
      <SportsPageWrap>
        {(specialLoading || bettingCartLoading) && (
          <SportsPageLoadingOverlay>
            <CustomLoading isGameStart info={bettingCartLoading ? '배팅 처리중입니다.' : ''} />
          </SportsPageLoadingOverlay>
        )}
        <SportsContentWrap>
          <MobileSportsSpecialHeader />
          <MobileSportsSpecialContent />
        </SportsContentWrap>
      </SportsPageWrap>

      <MobileSportsBettingCartSidebar />
      <MobileScrollButton />
    </>
  )
}

export default MobileSportsSpecialPage

const SportsPageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const SportsPageWrap = styled.div`
  width: 100%;
  background: #161719;
  min-height: calc(100vh - 105px);

  padding-bottom: 10px;
  position: relative;
`

const SportsContentWrap = styled.div`
  width: 100%;
`
