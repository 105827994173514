import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getHoldemGameLink } from '../../socketio/holdemSocketIOHandler'
import { JackpotGameType } from '../../utils/enums/Jackpot/JackpotEnums'
import { decodeAccessToken } from '../../utils/token'
import CustomAlert from '../CustomAlert'
import CustomLoading from '../CustomLoading'
import HoldemJackpotCounter from './HoldemJackpotCounter'

const RevolutionHoldemPage = () => {
  const [gameLoading, setGameLoading] = useState(false)

  const { revolutionHoldemInfo, holdemJackpotInfo } = useSelector(state => {
    const { memberInfo } = state

    return {
      revolutionHoldemInfo: memberInfo.revolutionHoldemInfo,
      holdemJackpotInfo: memberInfo.jackpotInfo[JackpotGameType.HOLDEM],
    }
  })

  const [alertPopupShow, setAlertPopupShow] = useState(false)
  // 경고창 용 팝업
  const [alertPopupMessage, setAlertPopupMessage] = useState('')

  const onCloseAlertPopup = () => {
    setAlertPopupShow(false)
    setAlertPopupMessage('')
  }

  const onChangeAlertPopupMessageHandler = text => {
    setAlertPopupShow(true)
    setAlertPopupMessage(text)
  }

  const holdemGameStartHandler = () => {
    if (gameLoading) return

    if (revolutionHoldemInfo.inspection) {
      alert('현재 점검중입니다.')
      return
    }

    setGameLoading(true)

    const params = {
      userId: decodeAccessToken().userId,
    }

    getHoldemGameLink(params)
      .then(res => {
        if (res.status) {
          window.open(res.data.gameUrl)
        } else {
          alert(res.data)
        }
      })
      .catch(error => {
        const { errorCode, message } = error.response.data
        switch (errorCode) {
          case 'MEMBER-1019':
            alert(`${message}`)
            break
          default:
            onChangeAlertPopupMessageHandler('잠시후에 다시 시도해주세요.')
        }
      })
      .finally(() => {
        setGameLoading(false)
      })
  }

  return (
    <>
      {alertPopupShow && <CustomAlert info={alertPopupMessage} onClickCloseHandler={onCloseAlertPopup} />}
      {gameLoading && <CustomLoading />}
      <HoldemPageWrapper>
        <HeaderWrap>
          <HoldemTitleBox>
            <HoldemTitleIconBox>
              <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-holdem.png`} alt="" />
            </HoldemTitleIconBox>
            <HoldemTitleTextBox>{revolutionHoldemInfo.title}</HoldemTitleTextBox>
          </HoldemTitleBox>
        </HeaderWrap>

        {holdemJackpotInfo?.jackpotShow && (
          <JackPotWrap>
            <JackPotContent>
              <HoldemJackpotCounter volume={holdemJackpotInfo?.jackpotAmount?.toFixed(1) ?? 0} />
            </JackPotContent>
          </JackPotWrap>
        )}

        <BannerWrap
          onClick={() => {
            holdemGameStartHandler()
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/img/holdem/holdem-banner.png`} alt="" />
        </BannerWrap>
      </HoldemPageWrapper>
    </>
  )
}

export default RevolutionHoldemPage

const HoldemPageWrapper = styled.div`
  width: 1277px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 66px);
`

const HeaderWrap = styled.div`
  width: 100%;
  padding: 20px;
  padding-top: 40px;
`

const HoldemTitleBox = styled.div`
  width: 100%;
  height: 26px;
`
const HoldemTitleIconBox = styled.div`
  float: left;

  img {
    width: auto;
    height: 24px;
  }
`

const HoldemTitleTextBox = styled.div`
  float: left;
  margin-left: 12px;
  margin-top: 1px;
  font-family: 'Malgun Gothic', dotum;
  color: #fff;
  font-size: 15px;
`

const BannerWrap = styled.div`
  width: 100%;
  display: block;

  cursor: pointer;

  img {
    width: 100%;
  }
`

const JackPotWrap = styled.div`
  width: 100%;
  height: 160px;
  background: url(${process.env.PUBLIC_URL}/img/main/jackpot/jackpot-bg.png) no-repeat center center;
  background-size: cover;
  display: flex;
  position: relative;

  margin-bottom: 20px;
`

const JackPotContent = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;

  // width: 100%;
  height: 100%;

  margin-left: auto;
  margin-right: 120px;
`
