import { jsx, css } from '@emotion/react'
import React, { useEffect, useRef, useState } from 'react'

const numberWithCommas = x => {
  if (x == null) return ''
  const [integerPart, decimalPart] = x.toString().split('.')
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return `₩${decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger}` // ₩ 추가
}

let runningTarget = 0

const HoldemJackpotCounter = ({ volume }) => {
  const counterRef = useRef(null)
  const [target, setTarget] = useState(-1)

  if (volume !== target) {
    runningTarget = volume
    setTarget(volume)
  }

  useEffect(() => {
    const canvas = counterRef.current
    const scale = 80 // 숫자 크기 조절 (기존 55 → 80)
    canvas.height = 92

    const ctx = canvas.getContext('2d')

    // 소수점 포함한 숫자 변환 및 배열로 분할
    const text = numberWithCommas(target).split('')

    // 픽셀 단위 너비 설정
    const defaultWidth = 50 // 숫자(0-9) 기본 너비
    const commaDotWidth = 30 // 쉼표(,)와 소수점(.) 너비
    const midWidth = 40 // 숫자-쉼표, 쉼표-숫자, 숫자-소수점 간 너비
    const wonSpacing = 10 // '₩' 뒤 여백

    let totalWidth = 0
    for (let i = 0; i < text.length; i++) {
      if (text[i] === '₩') {
        totalWidth += defaultWidth
        totalWidth += wonSpacing // '₩' 뒤 10px 추가
      } else if (text[i] === ',' || text[i] === '.') {
        totalWidth += commaDotWidth
      } else if (i > 0 && (text[i - 1] === ',' || text[i - 1] === '.')) {
        totalWidth += midWidth // 쉼표/소수점 뒤 숫자 간격
      } else if (i < text.length - 1 && (text[i + 1] === ',' || text[i + 1] === '.')) {
        totalWidth += midWidth // 숫자 뒤 쉼표/소수점 간격
      } else {
        totalWidth += defaultWidth
      }
    }

    canvas.width = totalWidth + 40

    const chars = '₩1234567890,.' // 모든 숫자, 쉼표, 소수점 포함

    const charMap = {}
    const offset = []
    const offsetV = []

    // 문자 매핑 (숫자, 콤마, 소수점 포함)
    for (let i = 0; i < chars.length; i++) {
      charMap[chars[i]] = i
    }

    let xPosition = 0 // 문자 X 좌표 관리

    for (let i = 0; i < text.length; i++) {
      const f = 30 + 8 * i
      offsetV[i] = 0.01 + 0.05 * f
      offset[i] = ((1 + f) * (0.05 * f + 2 * 0.01)) / 2
    }

    const loop = () => {
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      xPosition = 0 // X 위치 초기화

      for (let i = 0; i < text.length; i++) {
        ctx.shadowColor = '#111'
        ctx.shadowOffsetX = 3
        ctx.shadowOffsetY = 3
        ctx.shadowBlur = 4

        const gradient = ctx.createLinearGradient(0, -30, 0, 64)
        gradient.addColorStop(0, '#ffffff')
        gradient.addColorStop(0.3, '#ffd00e')
        gradient.addColorStop(0.6, '#d3922b')
        gradient.addColorStop(1, '#fafa0d')
        ctx.fillStyle = gradient

        ctx.textBaseline = 'middle'
        ctx.textAlign = 'center'
        ctx.setTransform(1, 0, 0, 1, 40, Math.floor(canvas.height / 2))

        let o = offset[i]
        while (o < 0) o++
        o %= 1
        const h = Math.ceil(canvas.height / 2 / scale)

        for (let j = -h; j < h; j++) {
          let c = charMap[text[i]] + j - Math.floor(offset[i])
          while (c < 0) c += chars.length
          c %= chars.length
          const s = 1 - Math.abs(j + o) / (canvas.height / 2 / scale + 1)
          ctx.globalAlpha = s
          ctx.font = `${scale * s}px Malgun Gothic`

          ctx.fillText(chars[c], xPosition, (j + o) * scale)

          //   ctx.fillText(chars[c], (scale * i) / 1.8, (j + o) * scale)
        }

        // 문자 간격 적용
        if (text[i] === '₩') {
          xPosition += defaultWidth
          xPosition += wonSpacing // '₩' 뒤 여백 추가
        } else if (text[i] === ',' || text[i] === '.') {
          xPosition += commaDotWidth
        } else if (i > 0 && (text[i - 1] === ',' || text[i - 1] === '.')) {
          xPosition += midWidth // 쉼표/소수점 뒤 숫자 간격
        } else if (i < text.length - 1 && (text[i + 1] === ',' || text[i + 1] === '.')) {
          xPosition += midWidth // 숫자 뒤 쉼표/소수점 간격
        } else {
          xPosition += defaultWidth
        }

        offset[i] -= offsetV[i]
        offsetV[i] -= 0.05
        if (offsetV[i] < 0.01) {
          offset[i] = 0
          offsetV[i] = 0
        }
      }

      if (runningTarget === target) {
        requestAnimationFrame(loop)
      }
    }
    requestAnimationFrame(loop)
  }, [target])

  return (
    <>
      <canvas ref={counterRef} />
    </>
  )
}

export default HoldemJackpotCounter
