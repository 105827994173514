import axios from '../base/axios'

export async function searchJackpot(params) {
  const response = await axios({
    url: '/tyson/member/v1/jackpot',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}
