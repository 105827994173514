export const DepositSortEnums = {
  KRW_MONEY: '원화',
  COIN_MONEY: '코인',
}

export const MoneySortEnums = {
  NORMAL_MONEY: '일반',
  HOLDEM_MONEY: '홀덤',
}

export const MemberDepositStatusEnums = {
  WAITING: '대기',
  APPROVE: '승인',
  CANCEL: '취소',
  APPLY: '신청',
}

export const RollingStatusEnums = {
  PROCESS: '진행중',
  STOP: '중단',
  COMPLETE: '완료',
  MANUAL_COMPLETE: '완료',
}

export const BonusTypeEnums = {
  BONUS_NONE: '보너스 없음',
  BONUS_SPORTS: '스포츠(첫충/매충)',
  BONUS_SPORTS_NEW: '스포츠 신규',
  BONUS_CASINO: '카지노(첫충/매충)',
  BONUS_CASINO_NEW: '카지노 신규',
  BONUS_SLOT_CASINO: '슬롯(첫충/매충)',
  BONUS_SLOT_CASINO_NEW: '슬롯 신규',
  BONUS_MINI_GAME: '미니게임(첫충/매충)',
  BONUS_MINI_GAME_NEW: '미니게임 신규',
  BONUS_HOLDEM: '홀덤(첫충/매충)',
  BONUS_HOLDEM_NEW: '홀덤 신규',
  BONUS_VIRTUAL_GAME: '가상게임(첫충/매충)',
  BONUS_VIRTUAL_GAME_NEW: '가상게임 신규',
  BONUS_PLUS: '전종목 이벤트머니',
}

export const BonusStatusEnums = {
  BONUS_SPORTS: '[스포츠 이용] : 포인트를 받겠습니다.',
  BONUS_SPORTS_ETC: '[스포츠 이용] : 단폴,두폴,실시간 이용시 포인트를 받지 않겠습니다.',
  BONUS_CASINO: '[카지노,슬롯 이용] : 포인트를 받지 않겠습니다.',
}

export const BonusStatusEnumsRadioOptions = () => {
  return Object.entries(BonusStatusEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'bonusType',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'bonusType',
          label: value,
          radioValue: key,
        },
  )
}
