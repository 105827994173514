import React from 'react'
import { MoneyWithdrawalEnumsStatusEnums } from '@/utils/enums/MyPage/myPageEnums'
import { commonReg2 } from '@/utils/validate/commonValidate'
import { convertToKstDateTime } from '@/utils/dateTime'
import WithdrawalDeleteRadio from '../../MoneyWithdrawal/WithdrawalDeleteRadio'
import { MoneySortTypeEnums } from '../../../utils/enums/MemberManage/MemberMoneyLogEnums'
import { DepositSortEnums } from '../../../utils/enums/MemberHistory/MemberDepositEnums'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const MoneyWithdrawalListData = (content, checkRadio, setCheckRadio) => {
  const columns = [
    {
      Header: '삭제',
      accessor: 'deleteRadio',
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <WithdrawalDeleteRadio rowInfo={original} setCheckRadio={setCheckRadio} checkRadio={checkRadio} />
      },
    },
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 25,
    },
    {
      Header: 'ID',
      accessor: 'memberId',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 80,
    },
    {
      Header: '구분',
      accessor: 'moneySort',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 80,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.moneySort) {
          case 'NORMAL_MONEY':
            return (
              <button
                type="button"
                style={{ background: '#7d2424', border: 'none', color: '#fff', fontWeight: '900', padding: '2px' }}
              >
                {MoneySortTypeEnums[original.moneySort]}
              </button>
            )
          case 'HOLDEM_MONEY':
            return (
              <button
                type="button"
                style={{ background: '#276687', border: 'none', color: '#fff', fontWeight: '900', padding: '2px' }}
              >
                {MoneySortTypeEnums[original.moneySort]}
              </button>
            )

          default:
            return (
              <button
                type="button"
                style={{ background: '#7d2424', border: 'none', color: '#fff', fontWeight: '900', padding: '2px' }}
              >
                {MoneySortTypeEnums.NORMAL_MONEY}
              </button>
            )
        }
      },
    },
    {
      Header: '원화/테더',
      accessor: 'depositSort',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.depositSort) {
          case 'KRW_MONEY':
            return (
              <button
                type="button"
                style={{ background: 'none', border: 'none', color: '#fff', fontWeight: '900', padding: '2px 5px' }}
              >
                {DepositSortEnums[original.depositSort]}
              </button>
            )
          case 'COIN_MONEY':
            return (
              <button type="button" style={{ background: 'none', border: 'none', color: '#fff', fontWeight: '900' }}>
                {DepositSortEnums[original.depositSort]}
              </button>
            )

          default:
            return (
              <button type="button" style={{ background: 'red', border: 'none', color: '#fff', fontWeight: '900' }}>
                {DepositSortEnums.KRW_MONEY}
              </button>
            )
        }
      },
    },
    {
      Header: '출금액(원/USDT)',
      accessor: 'applyMoneyMember',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.applyCoinAmount != null) {
          return (
            <span>
              {original.applyMoney?.toString().replace(commonReg2, ',')}원 /{' '}
              {original.applyCoinAmount?.toString()?.replace(commonReg2, ',')}USDT
            </span>
          )
        }
        return <span>{original.applyMoney?.toString().replace(commonReg2, ',')}원 / -</span>
      },
    },
    {
      Header: '처리일',
      accessor: 'executeDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 100,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.executeDate)
      },
    },
    {
      Header: '상태',
      accessor: 'status',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 30,
      Cell: r => {
        const { row } = r
        const { original } = row
        return MoneyWithdrawalEnumsStatusEnums[original.status]
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default MoneyWithdrawalListData
