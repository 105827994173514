import { useEffect, useState } from 'react'
import axios from '../base/axios'

/**
 * 회원 정보 조회 API
 */

export async function searchMemberInfo(params) {
  const response = await axios({
    url: '/tyson/member/v1/member/management/member-info',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 회원 정보 비밀번호 변경 API
 */
export async function updateMemberInfo(body) {
  const response = await axios({
    url: '/tyson/member/v1/member/management/member-info',
    method: 'PUT',
    data: body,
  })

  return response
}

// 쿠폰 사용을 위한 Select용 API
export async function searchCouponUseSelectList(params) {
  const response = await axios({
    url: '/tyson/member/v1/operation/management/couponuselist/select',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 회원 게임머니 조회 API
export async function searchMemberHoldingGameMoney(params) {
  const response = await axios({
    url: '/tyson/client/v1/member/money/holdinggamemoney',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchMemberHoldingGameMoney(params) {
  const [liveGameMoneyContent, setLiveGameMoneyContent] = useState([])
  const [slotGameMoneyContent, setSlotGameMoneyContent] = useState([])
  const [totalGameMoney, setTotalGameMOney] = useState(null)

  async function fetchSearchMemberHoldingGameMoney() {
    await searchMemberHoldingGameMoney(params).then(response => {
      setLiveGameMoneyContent(response.data.liveGameMoneyContent)
      setSlotGameMoneyContent(response.data.slotGameMoneyContent)
      setTotalGameMOney(response.data.totalGameMoney)
    })
  }

  useEffect(() => {
    fetchSearchMemberHoldingGameMoney()
  }, [params])

  return { liveGameMoneyContent, slotGameMoneyContent, totalGameMoney, fetchSearchMemberHoldingGameMoney }
}

// 유저 출석 API
export async function createUserAttendance(body) {
  const response = await axios({
    url: '/tyson/member/v1/attendance',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 유저 입금신청 API
export async function createUserMoneyDeposit(body) {
  const response = await axios({
    url: '/tyson/member/v3/asset/money/deposit',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 회원 머니 출금내역 삭제
export async function updateMemberMoneyHistoryDelete(body) {
  const response = await axios({
    url: '/tyson/member/v1/asset/money/deposit/moneylist',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

export async function createMemberCouponUse(body) {
  const response = await axios({
    url: '/tyson/member/v1/operation/management/member-couponuse',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 유저 출금신청 API
export async function createUserMoneyWithdrawal(body) {
  const response = await axios({
    url: '/tyson/member/v2/asset/moneywithdrawal',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 회원 콤프전환 신청 API
export async function createMemberPointExchange(body) {
  const response = await axios({
    url: '/tyson/member/v1/asset/member/point-exchange',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 코인 시세 조회 API
export async function searchCoinTicker(params) {
  const response = await axios({
    url: '/tyson/member/v1/asset/coin-ticker',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 자산 조회 API
export async function searchUserHoldingAsset(params) {
  const response = await axios({
    url: '/tyson/member/v1/asset/holdingasset',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchUserHoldingAsset(params, setLoading) {
  const [assetContent, setAssetContent] = useState([])
  async function fetchSearchUserHoldingAsset() {
    await searchUserHoldingAsset(params).then(response => {
      setAssetContent(response.data.content)
      setLoading(false)
    })
  }

  useEffect(() => {
    setLoading(true)
    fetchSearchUserHoldingAsset()
  }, [params])

  return { assetContent, fetchSearchUserHoldingAsset }
}

// 최소 입출금액 조회 API
export async function searchMinimumAmount(params) {
  const response = await axios({
    url: '/tyson/member/v1/header-info/system-info',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchMinimumAmount(params) {
  const [minumumContent, setMinimumContent] = useState([])
  async function fetchSearchMinimumAmount() {
    await searchMinimumAmount(params).then(response => {
      setMinimumContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchMinimumAmount()
  }, [params])

  return { minumumContent, fetchSearchMinimumAmount }
}

// 유저 게임 머니 조회
export async function searchGameMoney(params) {
  const response = await axios({
    url: '/tyson/member/v1/asset/game-money',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 머니 이동
export async function exchangeMoney(body) {
  const response = await axios({
    url: '/tyson/member/v1/asset/money-exchange',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 유저 입금보너스, 규정 조회
export async function searchDepositRule() {
  const response = await axios({
    url: '/tyson/member/v3/asset/money/deposit/rule',
    method: 'GET',
  }).then(res => {
    return res
  })

  return response
}

// 유저 출금 롤링, 규정 조회
export async function searchWithdrawalRule(params) {
  const response = await axios({
    url: '/tyson/member/v1/asset/moneywithdrawal/rule',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 카지노 머니 일괄 회수
export async function withdrawCasinoMoney(body) {
  const response = await axios({
    url: '/tyson/member/v1/asset/casino-withdraw',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })
  return response
}
