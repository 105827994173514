import { all, call, fork, put, select, take, takeLatest } from 'redux-saga/effects'
import { SportMarketType } from '../../utils/enums/SportsGame/SportsGameEnums'
import { SocketIOEventName } from '../../utils/enums/WebSocket/SocketIOEventName'
import { SocketIORequestMessageType } from '../../utils/enums/WebSocket/SocketIORequestMessageType'
import { emitPromise, socket } from '../../utils/socket'
import {
  onUpdatedBettingCartItemHandlerByFixture,
  onUpdatedBettingCartItemHandlerByMarket,
  removeAllBettingCartItem,
} from '../sportsBettingSlice'
import {
  fetchDomesticFixtureListAction,
  fetchDomesticFixtureMarketAction,
  setDomesticFixtureMarkets,
  setDomesticLoading,
  setDomesticSports,
  updateDomesticFixtureCompleteAction,
  updateFixtureByWebSocket,
  updateLiveScoreByWebSocket,
  updateMarketByWebSocket,
} from '../sportsDomesticInfoSlice'
import { createSocketChannel } from './createSocketChannel'

function getSportsDomesticInfo(state) {
  return state.sportsDomesticInfo
}

function getSportsBetting(state) {
  return state.sportsBetting
}

function* handleUpdateDomesticFixtureComplete() {
  try {
    const payload = {
      type: SocketIORequestMessageType.SPORTS_FIXTURE_DOMESTIC_COMPLETE,
      payload: '',
    }

    yield call(emitPromise, socket, SocketIOEventName.CREATE, payload)
  } catch (error) {
    //
  }
}

function* watchUpdateDomesticFixtureComplete() {
  yield takeLatest(updateDomesticFixtureCompleteAction, handleUpdateDomesticFixtureComplete)
}

function* handleFetchDomesticFixtureList() {
  try {
    const sportsDomesticInfo = yield select(getSportsDomesticInfo)

    const payload = {
      type: SocketIORequestMessageType.SPORTS_FIXTURE_LIST,
      payload: sportsDomesticInfo.domesticParams,
    }

    // 로딩 true
    yield put(setDomesticLoading(true))

    const sportsFixtureListRes = yield call(emitPromise, socket, SocketIOEventName.FIND, payload)

    if (sportsFixtureListRes.status) {
      const subscriptionPayload = {
        type: SocketIORequestMessageType.SPORTS_FIXTURE_DOMESTIC_SUBSCRIPTION,
        payload: {
          fixtureIds: sportsFixtureListRes.data.sportEvents.map(fixture => fixture.fixtureId),
        },
      }

      yield call(emitPromise, socket, SocketIOEventName.CREATE, subscriptionPayload)

      yield put(setDomesticSports(sportsFixtureListRes.data))
    }

    // 로딩 false
    yield put(setDomesticLoading(false))
  } catch (error) {
    yield put(setDomesticLoading(false))
  }
}

function* watchFetchDomesticFixtureList() {
  yield takeLatest(fetchDomesticFixtureListAction, handleFetchDomesticFixtureList)
}

function* handleFetchDomesticFixtureMarket() {
  try {
    const sportsDomesticInfo = yield select(getSportsDomesticInfo)

    if (sportsDomesticInfo.domesticMarketParams === -1) return

    const payload = {
      type: SocketIORequestMessageType.SPORTS_FIXTURE_MARKET_LIST,
      payload: sportsDomesticInfo.domesticMarketParams,
    }

    // 로딩 true
    yield put(setDomesticLoading(true))

    const sportsFixtureMarketRes = yield call(emitPromise, socket, SocketIOEventName.FIND, payload)

    if (sportsFixtureMarketRes.status) {
      const sportsBetting = yield select(getSportsBetting)

      yield put(
        setDomesticFixtureMarkets({
          sportEvent: sportsFixtureMarketRes.data.sportEvent,
          marketTab: sportsFixtureMarketRes.data.marketTab,
          bettingCartItems: sportsBetting.bettingCartList,
        }),
      )
    }

    // 로딩 false
    yield put(setDomesticLoading(false))
  } catch (error) {
    yield put(setDomesticLoading(false))
  }
}

function* watchFetchDomesticFixtureMarket() {
  yield takeLatest(fetchDomesticFixtureMarketAction, handleFetchDomesticFixtureMarket)
}

/**
 * 경기 데이터 다시 재호출
 */
function* onRefreshSportsDataMessage() {
  const channel = yield call(createSocketChannel, SocketIOEventName.REFRESH_SPORTS_DATA)

  while (true) {
    try {
      const message = yield take(channel)

      const sportsDomesticInfo = yield select(getSportsDomesticInfo)

      const sportsFixtureListpayload = {
        type: SocketIORequestMessageType.SPORTS_FIXTURE_LIST,
        payload: sportsDomesticInfo.domesticParams,
      }

      const sportsFixtureListRes = yield call(emitPromise, socket, SocketIOEventName.FIND, sportsFixtureListpayload)

      if (sportsFixtureListRes.status) {
        const subscriptionPayload = {
          type: SocketIORequestMessageType.SPORTS_FIXTURE_DOMESTIC_SUBSCRIPTION,
          payload: {
            fixtureIds: sportsFixtureListRes.data.sportEvents.map(fixture => fixture.fixtureId),
          },
        }

        yield call(emitPromise, socket, SocketIOEventName.CREATE, subscriptionPayload)

        yield put(setDomesticSports(sportsFixtureListRes.data))

        // 배팅 카트도 초기화 해부려
        yield put(removeAllBettingCartItem())
      }

      if (sportsDomesticInfo.domesticMarketParams !== -1) {
        const sportsFixtureMarketpayload = {
          type: SocketIORequestMessageType.SPORTS_FIXTURE_MARKET_LIST,
          payload: sportsDomesticInfo.domesticMarketParams,
        }

        const sportsFixtureMarketRes = yield call(
          emitPromise,
          socket,
          SocketIOEventName.FIND,
          sportsFixtureMarketpayload,
        )

        if (sportsFixtureMarketRes.status) {
          const sportsBetting = yield select(getSportsBetting)

          yield put(
            setDomesticFixtureMarkets({
              sportEvent: sportsFixtureMarketRes.data.sportEvent,
              marketTab: sportsFixtureMarketRes.data.marketTab,
              bettingCartItems: sportsBetting.bettingCartList,
            }),
          )
        }
      }

      // 배팅 카트에도 보내기
      yield put(
        onUpdatedBettingCartItemHandlerByMarket({
          data: sportsFixtureListRes.data,
          sportMarketType: SportMarketType.DOMESTIC,
        }),
      )
    } catch (e) {
      //   alert(e.message)
    }
  }
}

function* onUpdatedFixtureMessage() {
  const channel = yield call(createSocketChannel, SocketIOEventName.PRE_MATCH_FIXTURE_UPDATED)

  while (true) {
    try {
      const message = yield take(channel)

      if (message.sportMarketType === 'DOMESTIC') {
        yield put(updateFixtureByWebSocket(message))

        // 배팅 카트에도 보내기
        yield put(
          onUpdatedBettingCartItemHandlerByFixture({
            fixture: message?.fixture || {},
            sportMarketType: SportMarketType.DOMESTIC,
          }),
        )
      }
    } catch (e) {
      //   alert(e.message)
    }
  }
}

function* onUpdatedLiveScoreMessage() {
  const channel = yield call(createSocketChannel, SocketIOEventName.PRE_MATCH_LIVE_SCORE_UPDATED)

  while (true) {
    try {
      const message = yield take(channel)

      yield put(updateLiveScoreByWebSocket(message))
    } catch (e) {
      //   alert(e.message)
    }
  }
}
function* onUpdatedMarketMessage() {
  const channel = yield call(createSocketChannel, SocketIOEventName.PRE_MATCH_MARKET_UPDATED)

  while (true) {
    try {
      const message = yield take(channel)
      if (message.sportMarketType === 'DOMESTIC') {
        const sportsBetting = yield select(getSportsBetting)

        yield put(
          updateMarketByWebSocket({
            ...message,
            bettingCartItems: sportsBetting.bettingCartList,
          }),
        )

        // 배팅 카트에도 보내기
        yield put(
          onUpdatedBettingCartItemHandlerByMarket({
            market: message?.market || [],
            sportMarketType: SportMarketType.DOMESTIC,
          }),
        )
      }
    } catch (e) {
      //   alert(e.message)
    }
  }
}

export default function* sportsDomesticSaga() {
  yield all([
    fork(onUpdatedFixtureMessage),
    fork(onUpdatedLiveScoreMessage),
    fork(onUpdatedMarketMessage),
    fork(onRefreshSportsDataMessage),

    watchFetchDomesticFixtureList(),
    watchFetchDomesticFixtureMarket(),
    watchUpdateDomesticFixtureComplete(),
  ])
}
