import { MobileTopWrap } from '@/shared/components/MoneyCustomElement'
import Error from '@/shared/components/form/Error'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { commonReg2 } from '@/utils/validate/commonValidate'
import { MoneyWithdrawalValidate } from '@/utils/validate/moneyFormValidate'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { FaChevronLeft, FaTrash } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import parse from 'style-to-object'
import ReactHtmlParser from 'react-html-parser'
import { searchMemberMoneyWithdrawal } from '../../api/memberHistory/memberHistoryApi'
import {
  createUserMoneyWithdrawal,
  searchMinimumAmount,
  searchWithdrawalRule,
  updateMemberMoneyHistoryDelete,
  withdrawCasinoMoney,
} from '../../api/memberManage/memberManageApi'
import CustomLoading from '../../containers/CustomLoading'
import { fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import { onClickTitleHandler } from '../../redux/modalSlice'
import Portal from '../../shared/components/Portal'
import {
  MobileMoneyFormButtomInfo,
  MoneyFormInput,
  MoneyFormInputWrap,
  MoneyFormModifyButtomInfo,
} from '../../shared/components/form/newForm'
import { HermesMainBorder, HermesMainText, colorMainText } from '../../utils/palette'
import MobileMoneyWithdrawalListData from '../mobileMyPage/mobileMoneyWithdrawalHistory/MobileMoneyWithdrawalListData'
import { CustomButtonInfo } from '../../shared/components/MobileMoneyCustomElement'
import { BonusTypeEnums, RollingStatusEnums } from '../../utils/enums/MemberHistory/MemberDepositEnums'
import MobileCoinWithdrawModalContent from './MobileCoinWithdrawModalContent'

const MobileMoneyWithdrawalForm = ({ visible, onClose }) => {
  const [loading, setLoading] = useState(true)
  const [myInfoLoading, setMyInfoLoading] = useState(true)
  const dispatch = useDispatch()

  /**
   * 회원 머니출금내역 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [historyParams, setHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  // const { totalElement, content } = useSearchMemberMoneyWithdrawal(params)

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  useEffect(() => {
    setLoading(true)
    searchMemberMoneyWithdrawal(historyParams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [historyParams])

  const fetchSearchMemberMoneyWithdrawal = async () => {
    setLoading(true)
    await searchMemberMoneyWithdrawal(historyParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const [checkRadio, setCheckRadio] = useState([])
  const [moneyWithdrawalListData, setMoneyWithdrawalListData] = useState(
    MobileMoneyWithdrawalListData(content, checkRadio, setCheckRadio),
  )

  useEffect(() => {
    setMoneyWithdrawalListData(MobileMoneyWithdrawalListData(content, checkRadio, setCheckRadio))
  }, [content, checkRadio])

  const [rows, setData] = useState(moneyWithdrawalListData.tableRowsData)
  useEffect(() => {
    setData(moneyWithdrawalListData.tableRowsData)
  }, [moneyWithdrawalListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  const { holdingMoney, holdemMoneyAmount, totalGameMoney, userId, userName, bankName, accountNum } = useSelector(
    state => {
      const { memberInfo } = state

      return {
        holdingMoney: memberInfo.memberHoldingMoney,
        totalGameMoney: memberInfo.memberTotalGameMoney,
        holdemMoneyAmount: memberInfo.holdemMoneyAmount,
        userId: memberInfo.userId,
        userName: memberInfo.userName,
        bankName: memberInfo.bankName,
        accountNum: memberInfo.accountNum,
      }
    },
  )

  // 최소 출금액
  const [minimumWithdrawal, setMinimumWithdrawal] = useState('0')
  const [withdrawalMaxAmount, setWithdrawalMaxAmount] = useState('0')
  const [withdrawlTerm, setWithdrawalMaxTerm] = useState(0)

  useEffect(() => {
    searchMinimumAmount()
      .then(res => {
        setMinimumWithdrawal(res.data.minimumWithdrawal)
        setWithdrawalMaxAmount(res.data.withdrawalMaxAmount)
        setWithdrawalMaxTerm(res.data.withdrawlTerm)
        setMyInfoLoading(false)
      })
      .catch(error => {
        setMyInfoLoading(false)
      })
  }, [])

  const [moneySortRadio, setMoneySortRadio] = useState('NORMAL_MONEY') // 초기값 설정

  const handleChange = event => {
    setMoneySortRadio(event.target.value) // 선택된 값 업데이트
  }

  // 출금 금액
  const [applyMoney, setApplyMoney] = useState('0')

  const onClickMoneyHandler = name => {
    setApplyMoney(prev => (Number(prev.replaceAll(',', '')) + Number(name)).toString().replace(commonReg2, ','))
  }

  const onClearClickHandler = () => {
    setApplyMoney('0')
  }

  // api 여러번 호출 막기
  const [apiFlag, setApiFlag] = useState(false)

  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e)
    }
  }

  const close = e => {
    if (onClose) {
      onClose(e)
    }
  }
  const [selectedIndex, setSelectedIndex] = useState(0)

  const [isShowWithdrawalRuleContent, setIsShowWithdrawalRuleContent] = useState(false)
  const [withdrawalRuleContent, setWithdrawalRuleContent] = useState('')
  const [newTwoFolderRolling, setNewTwoFolderRolling] = useState(0)
  const [newMultiFolderRolling, setNewMultiFolderRolling] = useState(0)
  const [newSingleLiveFolderRolling, setNewSingleLiveFolderRolling] = useState(0)
  const [newMiniGameRolling, setNewMiniGameRolling] = useState(0)
  const [newCasinoRolling, setNewCasinoRolling] = useState(0)
  const [newSlotRolling, setNewSlotRolling] = useState(0)
  const [newVirtualGameRolling, setNewVirtualGameRolling] = useState(0)
  const [newHoldemRolling, setNewHoldemRolling] = useState(0)
  const [firstTwoFolderRolling, setFirstTwoFolderRolling] = useState(0)
  const [firstMultiFolderRolling, setFirstMultiFolderRolling] = useState(0)
  const [firstSingleLiveFolderRolling, setFirstSingleLiveFolderRolling] = useState(0)
  const [firstMiniGameRolling, setFirstMiniGameRolling] = useState(0)
  const [firstCasinoRolling, setFirstCasinoRolling] = useState(0)
  const [firstSlotRolling, setFirstSlotRolling] = useState(0)
  const [firstVirtualGameRolling, setFirstVirtualGameRolling] = useState(0)
  const [firstHoldemRolling, setFirstHoldemRolling] = useState(0)
  const [everyTwoFolderRolling, setEveryTwoFolderRolling] = useState(0)
  const [everyMultiFolderRolling, setEveryMultiFolderRolling] = useState(0)
  const [everySingleLiveFolderRolling, setEverySingleLiveFolderRolling] = useState(0)
  const [everyMiniGameRolling, setEveryMiniGameRolling] = useState(0)
  const [everyCasinoRolling, setEveryCasinoRolling] = useState(0)
  const [everySlotRolling, setEverySlotRolling] = useState(0)
  const [everyVirtualGameRolling, setEveryVirtualGameRolling] = useState(0)
  const [everyHoldemRolling, setEveryHoldemRolling] = useState(0)
  const [twoFolderRollingNotBonus, setTwoFolderRollingNotBonus] = useState(0)
  const [multiFolderRollingNotBonus, setMultiFolderRollingNotBonus] = useState(0)
  const [singleLiveFolderRollingNotBonus, setSingleLiveFolderRollingNotBonus] = useState(0)
  const [miniGameRollingNotBonus, setMiniGameRollingNotBonus] = useState(0)
  const [casinoRollingNotBonus, setCasinoRollingNotBonus] = useState(0)
  const [slotRollingNotBonus, setSlotRollingNotBonus] = useState(0)
  const [virtualGameRollingNotBonus, setVirtualGameRollingNotBonus] = useState(0)
  const [holdemRollingNotBonus, setHoldemRollingNotBonus] = useState(0)

  const [bonusPer, setBonusPer] = useState(0)
  const [bonusType, setBonusType] = useState(null)
  const [totalDepositAmount, setTotalDepositAmount] = useState(0)
  const [withdrawRollingStatus, setWithdrawRollingStatus] = useState(null)
  const [currentRollingRatio, setCurrentRollingRatio] = useState(0)
  const [necessarySportsSingleLiveBettingAmount, setNecessarySportsSingleLiveBettingAmount] = useState(0)
  const [necessarySportsTwoBettingAmount, setNecessarySportsTwoBettingAmount] = useState(0)
  const [necessarySportsMultiBettingAmount, setNecessarySportsMultiBettingAmount] = useState(0)
  const [necessaryVirtualBettingAmount, setNecessaryVirtualBettingAmount] = useState(0)
  const [necessaryMiniGameBettingAmount, setNecessaryMiniGameBettingAmount] = useState(0)
  const [necessaryCasinoBettingAmount, setNecessaryCasinoBettingAmount] = useState(0)
  const [necessarySlotBettingAmount, setNecessarySlotBettingAmount] = useState(0)
  const [necessaryHoldemBettingAmount, setNecessaryHoldemBettingAmount] = useState(0)

  useEffect(() => {
    if (!moneySortRadio) return
    searchWithdrawalRule({ moneySort: moneySortRadio }).then(res => {
      setWithdrawalRuleContent(res.data.withdrawalRuleContent)
      setNewTwoFolderRolling(res.data.newTwoFolderRolling)
      setNewMultiFolderRolling(res.data.newMultiFolderRolling)
      setNewSingleLiveFolderRolling(res.data.newSingleLiveFolderRolling)
      setNewMiniGameRolling(res.data.newMiniGameRolling)
      setNewCasinoRolling(res.data.newCasinoRolling)
      setNewSlotRolling(res.data.newSlotRolling)
      setNewVirtualGameRolling(res.data.newVirtualGameRolling)
      setNewHoldemRolling(res.data.newHoldemRolling)
      setFirstTwoFolderRolling(res.data.firstTwoFolderRolling)
      setFirstMultiFolderRolling(res.data.firstMultiFolderRolling)
      setFirstSingleLiveFolderRolling(res.data.firstSingleLiveFolderRolling)
      setFirstMiniGameRolling(res.data.firstMiniGameRolling)
      setFirstCasinoRolling(res.data.firstCasinoRolling)
      setFirstSlotRolling(res.data.firstSlotRolling)
      setFirstVirtualGameRolling(res.data.firstVirtualGameRolling)
      setFirstHoldemRolling(res.data.firstHoldemRolling)
      setEveryTwoFolderRolling(res.data.everyTwoFolderRolling)
      setEveryMultiFolderRolling(res.data.everyMultiFolderRolling)
      setEverySingleLiveFolderRolling(res.data.everySingleLiveFolderRolling)
      setEveryMiniGameRolling(res.data.everyMiniGameRolling)
      setEveryCasinoRolling(res.data.everyCasinoRolling)
      setEverySlotRolling(res.data.everySlotRolling)
      setEveryVirtualGameRolling(res.data.everyVirtualGameRolling)
      setEveryHoldemRolling(res.data.everyHoldemRolling)
      setTwoFolderRollingNotBonus(res.data.twoFolderRollingNotBonus)
      setMultiFolderRollingNotBonus(res.data.multiFolderRollingNotBonus)
      setSingleLiveFolderRollingNotBonus(res.data.singleLiveFolderRollingNotBonus)
      setMiniGameRollingNotBonus(res.data.miniGameRollingNotBonus)
      setCasinoRollingNotBonus(res.data.casinoRollingNotBonus)
      setSlotRollingNotBonus(res.data.slotRollingNotBonus)
      setVirtualGameRollingNotBonus(res.data.virtualGameRollingNotBonus)
      setHoldemRollingNotBonus(res.data.holdemRollingNotBonus)
      setBonusPer(res.data.withdrawalRollingModel.bonusPer)
      setBonusType(res.data.withdrawalRollingModel.bonusType)
      setTotalDepositAmount(res.data.withdrawalRollingModel.totalDepositAmount)
      setWithdrawRollingStatus(res.data.withdrawalRollingModel.withdrawRollingStatus)
      setCurrentRollingRatio(res.data.withdrawalRollingModel.currentRollingRatio)
      setNecessarySportsSingleLiveBettingAmount(res.data.withdrawalRollingModel.necessarySportsSingleLiveBettingAmount)
      setNecessarySportsTwoBettingAmount(res.data.withdrawalRollingModel.necessarySportsTwoBettingAmount)
      setNecessarySportsMultiBettingAmount(res.data.withdrawalRollingModel.necessarySportsMultiBettingAmount)
      setNecessaryVirtualBettingAmount(res.data.withdrawalRollingModel.necessaryVirtualBettingAmount)
      setNecessaryMiniGameBettingAmount(res.data.withdrawalRollingModel.necessaryMiniGameBettingAmount)
      setNecessaryCasinoBettingAmount(res.data.withdrawalRollingModel.necessaryCasinoBettingAmount)
      setNecessarySlotBettingAmount(res.data.withdrawalRollingModel.necessarySlotBettingAmount)
      setNecessaryHoldemBettingAmount(res.data.withdrawalRollingModel.necessaryHoldemBettingAmount)
    })
  }, [moneySortRadio])

  const onSubmit = e => {
    if (apiFlag) return
    if (withdrawRollingStatus !== 'COMPLETE' && withdrawRollingStatus !== 'MANUAL_COMPLETE') {
      return
    }
    const checkMoney = applyMoney?.toString().replaceAll(',', '')
    if (Number(checkMoney) <= 0) {
      alert('금액을 다시 확인해주세요.')
      return
    }

    if (Number(checkMoney) > 0 && checkMoney?.substr(checkMoney.length - 4, 4) !== '0000') {
      alert('10,000원 단위로 입력이 가능합니다.')
      return
    }
    if (Number(checkMoney) > Number(withdrawalMaxAmount)) {
      alert(`최대 ${withdrawalMaxAmount?.toString().replace(commonReg2, ',')}원 이하로 출금 가능합니다.`)
      return
    }

    if (moneySortRadio === 'NORMAL_MONEY') {
      if (Number(checkMoney) > Number(holdingMoney)) {
        alert('보유머니를 다시 확인해주세요.')
        return
      }
    } else {
      if (Number(checkMoney) > Number(holdemMoneyAmount)) {
        alert('홀덤머니를 다시 확인해주세요.')
        return
      }
    }
    setApiFlag(true)

    const body = {
      moneySort: moneySortRadio,
      applyMoney: checkMoney,
    }

    createUserMoneyWithdrawal(body)
      .then(res => {
        alert('출금신청이 완료되었습니다.')
        setApiFlag(false)
        setApplyMoney(0)
        fetchSearchMemberMoneyWithdrawal()
        dispatch(fetchMemberInfoAction())
        setApplyMoney('0')
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'MONEY-1007':
            alert('현재 처리중인 입금 신청이 존재합니다.\n입금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1008':
            alert('현재 처리중인 출금 신청이 존재합니다.\n출금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1009':
            alert('현재 머니이동을 처리중입니다.\n잠시 후 다시 시도해주세요.')
            break
          case 'ASSET-1011':
            alert('출금이 불가능한 시간입니다.')
            break
          case 'ASSET-1005':
            alert(message)
            break
          case 'ASSET-1006':
            alert('출금 규정을 확인해주시기 바랍니다.')
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          case 'ASSET-1003':
            alert(message)
            break
          case 'ASSET-1015':
            alert(message)
            break
          default:
            alert('출금신청이 실패했습니다. 다시 시도해주세요.')
        }

        setApiFlag(false)
        setApplyMoney('0')
        fetchSearchMemberMoneyWithdrawal()
      })
  }

  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={{ maxWidth: '100%' }} alt="" />
    }
  }

  const [deleteLoading, setDeleteLoading] = useState(false)
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 목록을 선택해주세요.')
      return
    }

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      updateMemberMoneyHistoryDelete({
        moneyType: 'WITHDRAWAL',
        moneyHistoryKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchMemberMoneyWithdrawal()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제 실패했습니다.')
          setCheckRadio([])
          fetchSearchMemberMoneyWithdrawal()
        })
    }
  }

  function formatAmount(amount) {
    if (amount != null) {
      return amount >= 0 ? `${amount.toString().replace(commonReg2, ',')} 원` : '0 원'
    }
    return '0 원'
  }

  const [alertPopupShow, setAlertPopupShow] = useState(false)
  // 경고창 용 팝업
  const [alertPopupMessage, setAlertPopupMessage] = useState('')

  const onCloseAlertPopup = () => {
    setAlertPopupShow(false)
    setAlertPopupMessage('')
  }

  const [moneyExchangeLoading, setMoneyExchangeLoading] = useState(false)

  const onChangeAlertPopupMessageHandler = text => {
    setAlertPopupShow(true)
    setAlertPopupMessage(text)
  }

  const onClickCasinoMoneyWithdrawHandler = () => {
    if (moneyExchangeLoading) {
      onChangeAlertPopupMessageHandler('카지노 머니 회수 진행중입니다. 잠시만 기다려주세요.')
      return
    }
    if (totalGameMoney <= 0) return

    setMoneyExchangeLoading(true)

    withdrawCasinoMoney()
      .then(res => {
        dispatch(fetchMemberInfoAction())
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'GAME-1014':
            onChangeAlertPopupMessageHandler('카지노 머니 동기화중입니다. \n잠시후에 다시 시도해주세요.')
            break
          case 'ASSET-7001':
            onChangeAlertPopupMessageHandler('잠시후 다시 시도해주세요.')
            break
          default:
            onChangeAlertPopupMessageHandler('처리 실패했습니다.')
        }
      })
      .finally(() => {
        setMoneyExchangeLoading(false)
      })
  }

  const [coinModalShow, setCoinModalShow] = useState(false)

  const onClickCoinModalHandler = () => {
    const checkMoney = applyMoney?.toString().replaceAll(',', '')

    if (Number(checkMoney) <= 0) {
      alert('금액을 다시 확인해주세요.')
      return
    }
    if (Number(checkMoney) > 0 && checkMoney?.substr(checkMoney.length - 4, 4) !== '0000') {
      alert('10,000원 단위로 입력이 가능합니다.')
      return
    }

    if (Number(checkMoney) > Number(withdrawalMaxAmount)) {
      alert(`최대 ${withdrawalMaxAmount?.toString().replace(commonReg2, ',')}원 이하로 출금 가능합니다.`)
      return
    }

    if (moneySortRadio === 'NORMAL_MONEY') {
      if (Number(checkMoney) > Number(holdingMoney)) {
        alert('보유머니를 다시 확인해주세요.')
        return
      }
    } else {
      if (Number(checkMoney) > Number(holdemMoneyAmount)) {
        alert('홀덤머니를 다시 확인해주세요.')
        return
      }
    }

    setCoinModalShow(true)
  }

  const onCloseCoinModalHandler = () => {
    setCoinModalShow(false)
    fetchSearchMemberMoneyWithdrawal()
    setApplyMoney('')
  }

  return (
    <Portal elementId="signup-modal">
      {coinModalShow && (
        <Portal elementId="signup-modal">
          <SignUpModalOverlay visible={coinModalShow} />
          <SignUpModalWrapper
            onClick={e => {
              if (e.target === e.currentTarget) {
                onCloseCoinModalHandler(e)
              }
            }}
            tabIndex="-1"
            visible={coinModalShow}
            className="sidebar-container"
          >
            <SignUpModalInner tabIndex="0">
              <SignUpModalHeader>
                <SignUpModalCloseButton
                  onClick={onCloseCoinModalHandler}
                  src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
                  alt=""
                />
              </SignUpModalHeader>
              <SignUpModalContentWrap>
                <MobileCoinWithdrawModalContent
                  applyWithdrawInfo={{
                    applyMoney: applyMoney?.toString().replaceAll(',', ''),
                    moneySort: moneySortRadio,
                  }}
                  onCloseCoinModalHandler={onCloseCoinModalHandler}
                />
              </SignUpModalContentWrap>
            </SignUpModalInner>
          </SignUpModalWrapper>
        </Portal>
      )}
      <SignUpModalOverlay visible={visible} />
      <SignUpModalWrapper onClick={onMaskClick} tabIndex="-1" visible={visible}>
        <SignUpModalInner tabIndex="0">
          <SignUpModalHeader>
            <SignUpModalHeaderLogo src={`${process.env.PUBLIC_URL}/img/main/Logo.png`} alt="" />
            <SignUpModalCloseButton
              onClick={close}
              src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
              alt=""
            />
          </SignUpModalHeader>
          <SignUpModalContentWrap>
            <MobileTopWrap>
              {myInfoLoading || moneyExchangeLoading ? (
                <CustomLoading />
              ) : (
                <>
                  <ContentWrap>
                    <ContentRowWrap>
                      <FaChevronLeft
                        onClick={e => {
                          dispatch(onClickTitleHandler('MYPAGE'))
                        }}
                      />
                      <ContentRowSmall>
                        <div>
                          <span style={{ color: '#b2b2b2' }}>보유머니</span>
                          <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
                            {holdingMoney?.toString().replace(commonReg2, ',')} 원
                          </span>
                        </div>
                      </ContentRowSmall>
                      <ContentRowSmall>
                        <div>
                          <span style={{ color: '#b2b2b2' }}>홀덤머니</span>
                          <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
                            {holdemMoneyAmount?.toString().replace(commonReg2, ',')} 원
                          </span>
                        </div>
                      </ContentRowSmall>
                      <ContentRowSmall>
                        <div>
                          <span style={{ color: '#b2b2b2' }}>카지노 머니</span>
                          <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
                            {totalGameMoney?.toString().replace(commonReg2, ',')} 원
                          </span>
                        </div>
                      </ContentRowSmall>
                    </ContentRowWrap>
                    <ContentRowThirdWrap>
                      <ContentRowBig selectedIndex={selectedIndex}>
                        <div onClick={() => setSelectedIndex(0)}>출금</div>
                        <div onClick={() => setSelectedIndex(1)}>출금내역</div>
                        <div onClick={() => setSelectedIndex(2)}>출금규정</div>
                      </ContentRowBig>
                    </ContentRowThirdWrap>
                    <ContentInfo>
                      {
                        // 여기서 구분
                      }
                      {selectedIndex === 0 && (
                        <>
                          <div
                            style={{
                              fontWeight: '700',
                              display: 'flex',
                              gap: '10px',
                              width: '100%',
                              padding: '24px',
                              fontSize: '12px',
                              alignItems: 'center',
                            }}
                          >
                            <div
                              style={{
                                fontWeight: '700',
                                display: 'flex',
                                gap: '5px',
                                width: '100%',
                                fontSize: '12px',
                                alignItems: 'center',
                              }}
                            >
                              <input
                                type="radio"
                                id="NORMAL_MONEY"
                                name="moneySort"
                                value="NORMAL_MONEY"
                                checked={moneySortRadio === 'NORMAL_MONEY'}
                                onChange={handleChange}
                              />
                              <label htmlFor="NORMAL_MONEY" style={{ cursor: 'pointer', marginRight: '10px' }}>
                                보유머니 출금
                              </label>
                              <input
                                type="radio"
                                id="HOLDEM_MONEY"
                                name="moneySort"
                                value="HOLDEM_MONEY"
                                checked={moneySortRadio === 'HOLDEM_MONEY'}
                                onChange={handleChange}
                              />
                              <label htmlFor="HOLDEM_MONEY" style={{ cursor: 'pointer' }}>
                                홀덤머니 출금
                              </label>
                            </div>
                          </div>
                          <PageOverlayWrap>
                            {withdrawRollingStatus !== 'COMPLETE' && withdrawRollingStatus !== 'MANUAL_COMPLETE' && (
                              <PageOverlay>
                                <PageOverlayImg src={`${process.env.PUBLIC_URL}/img/rolling/prohibit.png`} />
                                <PageOverlayContent>롤링 완료 후 출금이 가능합니다.</PageOverlayContent>
                              </PageOverlay>
                            )}
                            <Form
                              onSubmit={onSubmit}
                              initialValues={{
                                memberId: userId,
                                memberName: userName,
                                bankName,
                                accountHolder: accountNum,
                                applyMoney,
                                // TODO 지갑머니 < 출금머니가 클때 validate 체크할려고 넣은 변수
                                holdingMoney,
                                minimumWithdrawal,
                              }}
                              validate={MoneyWithdrawalValidate}
                            >
                              {({ handleSubmit, form: { reset } }) => (
                                <div onSubmit={handleSubmit}>
                                  <ContentRowSecondWrap>
                                    <CustomerCenterFormGroup style={{ marginBottom: '30px' }}>
                                      <CustomerCenterFormLabel>
                                        출금신청 금액
                                        <div
                                          style={{
                                            marginTop: '5px',
                                            width: '100%',
                                            fontSize: '12px',
                                            fontWeight: '400',
                                          }}
                                        >
                                          <p>
                                            * 출금 최소액은{' '}
                                            <span style={{ color: `${HermesMainText}` }}>
                                              {' '}
                                              {minimumWithdrawal?.toString().replace(commonReg2, ',') || 0}원
                                            </span>{' '}
                                            출금 간격은{' '}
                                            <span style={{ color: `${HermesMainText}` }}> {withdrawlTerm || 0}분</span>
                                            이며,
                                            <br />
                                            &nbsp; 1회 출금 최대액
                                            <span style={{ color: `${HermesMainText}` }}> (레벨별 상이)</span>은{' '}
                                            <span style={{ color: `${HermesMainText}` }}>
                                              {' '}
                                              {withdrawalMaxAmount?.toString().replace(commonReg2, ',') || 0}원
                                            </span>{' '}
                                            입니다.
                                          </p>
                                        </div>
                                      </CustomerCenterFormLabel>
                                      <CustomCenterFormField>
                                        <Field name="applyMoney">
                                          {({ input, meta }) => (
                                            <MoneyFormInputWrap>
                                              <MoneyFormInput
                                                {...input}
                                                type="text"
                                                value={input.value}
                                                onChange={e => {
                                                  input.onChange(e)
                                                  setApplyMoney(e.target.value)
                                                }}
                                              />
                                              {meta.error && <Error error={meta.error} isMobile />}
                                            </MoneyFormInputWrap>
                                          )}
                                        </Field>
                                      </CustomCenterFormField>
                                    </CustomerCenterFormGroup>
                                    <CustomerCenterFormGroup>
                                      <CustomCenterFormField>
                                        <MoneyFormButton
                                          size="sm"
                                          type="button"
                                          variant="outline-secondary"
                                          name="10000"
                                          onClick={e => {
                                            onClickMoneyHandler('10000')
                                          }}
                                        >
                                          <MobileMoneyFormButtomInfo>1만원</MobileMoneyFormButtomInfo>
                                        </MoneyFormButton>
                                        <MoneyFormButton
                                          size="sm"
                                          type="button"
                                          variant="outline-secondary"
                                          name="50000"
                                          onClick={e => {
                                            onClickMoneyHandler('50000')
                                          }}
                                        >
                                          <MobileMoneyFormButtomInfo>5만원</MobileMoneyFormButtomInfo>
                                        </MoneyFormButton>
                                        <MoneyFormButton
                                          size="sm"
                                          type="button"
                                          variant="outline-secondary"
                                          name="100000"
                                          onClick={e => {
                                            onClickMoneyHandler('100000')
                                          }}
                                        >
                                          <MobileMoneyFormButtomInfo>10만원</MobileMoneyFormButtomInfo>
                                        </MoneyFormButton>
                                        <MoneyFormButton
                                          size="sm"
                                          type="button"
                                          variant="outline-secondary"
                                          name="500000"
                                          onClick={e => {
                                            onClickMoneyHandler('500000')
                                          }}
                                        >
                                          <MobileMoneyFormButtomInfo>50만원</MobileMoneyFormButtomInfo>
                                        </MoneyFormButton>
                                      </CustomCenterFormField>
                                    </CustomerCenterFormGroup>
                                    <CustomerCenterFormGroup>
                                      <CustomCenterFormField>
                                        <MoneyFormButton
                                          size="sm"
                                          type="button"
                                          variant="outline-secondary"
                                          name="1000000"
                                          onClick={e => {
                                            onClickMoneyHandler('1000000')
                                          }}
                                        >
                                          <MobileMoneyFormButtomInfo>100만원</MobileMoneyFormButtomInfo>
                                        </MoneyFormButton>
                                        <MoneyFormButton
                                          size="sm"
                                          type="button"
                                          variant="outline-secondary"
                                          name="3000000"
                                          onClick={e => {
                                            onClickMoneyHandler('3000000')
                                          }}
                                        >
                                          <MobileMoneyFormButtomInfo>300만원</MobileMoneyFormButtomInfo>
                                        </MoneyFormButton>
                                        <MoneyFormButton
                                          size="sm"
                                          type="button"
                                          variant="outline-secondary"
                                          name="10000000"
                                          onClick={e => {
                                            onClickMoneyHandler('3000000')
                                          }}
                                        >
                                          <MobileMoneyFormButtomInfo>1000만원</MobileMoneyFormButtomInfo>
                                        </MoneyFormButton>
                                        <MoneyFormButton
                                          size="sm"
                                          type="button"
                                          variant="outline-secondary"
                                          name="0"
                                          onClick={e => {
                                            onClearClickHandler()
                                          }}
                                        >
                                          <MobileMoneyFormButtomInfo>
                                            <FaTrash />
                                          </MobileMoneyFormButtomInfo>
                                        </MoneyFormButton>
                                      </CustomCenterFormField>
                                    </CustomerCenterFormGroup>
                                    <div
                                      style={{ width: '100%', display: 'flex', gap: '10px', justifyContent: 'center' }}
                                    >
                                      <MoneyButton variant="primary" type="submit" onClick={onSubmit}>
                                        <CustomMoneyFormModifyButtomInfo>원화 출금신청</CustomMoneyFormModifyButtomInfo>
                                      </MoneyButton>
                                      <MoneyButton
                                        style={{ background: 'darkorange', border: 'solid 1px darkorange' }}
                                        variant="primary"
                                        type="submit"
                                        onClick={onClickCoinModalHandler}
                                      >
                                        <CustomMoneyFormModifyButtomInfo>테더 출금신청</CustomMoneyFormModifyButtomInfo>
                                      </MoneyButton>
                                    </div>
                                  </ContentRowSecondWrap>
                                </div>
                              )}
                            </Form>
                          </PageOverlayWrap>

                          <RollingTopWrap>
                            <RollingWrap>
                              <RollingHeaderWrap>
                                <RollingHeaderInfo1>
                                  {BonusTypeEnums[bonusType]} {bonusPer}% &nbsp;-
                                </RollingHeaderInfo1>
                                <RollingHeaderInfo2>
                                  &nbsp;{totalDepositAmount?.toString().replace(commonReg2, ',')}&nbsp;원
                                </RollingHeaderInfo2>
                              </RollingHeaderWrap>
                              <RollingContentWrap1>
                                <RollingContentBox>
                                  <RollingCompleteImg src={`${process.env.PUBLIC_URL}/img/rolling/rollingcheck.png`} />
                                  {withdrawRollingStatus === 'COMPLETE' ||
                                  withdrawRollingStatus === 'MANUAL_COMPLETE' ? (
                                    <RollingContentTitleWrap>
                                      <RollingContentTitle1>
                                        {RollingStatusEnums[withdrawRollingStatus]}
                                      </RollingContentTitle1>
                                      <RollingContentTitle2 style={{ color: `${HermesMainText}` }}>
                                        * 롤링을 완료했으므로 출금이 가능합니다.
                                      </RollingContentTitle2>
                                    </RollingContentTitleWrap>
                                  ) : (
                                    <RollingContentTitleWrap>
                                      <RollingContentTitle1>
                                        {RollingStatusEnums[withdrawRollingStatus]}
                                      </RollingContentTitle1>
                                      <RollingContentTitle2 style={{ color: 'red' }}>
                                        * 나머지 롤링을 채운 후 출금이 가능합니다.
                                      </RollingContentTitle2>
                                    </RollingContentTitleWrap>
                                  )}
                                </RollingContentBox>
                              </RollingContentWrap1>
                              <RollingContentWrap2>
                                <ProgressBarContainer>
                                  <FilledBar percentage={currentRollingRatio} />
                                  <PercentageText>
                                    {currentRollingRatio >= 100 ? '100%' : `${currentRollingRatio}%`} &nbsp;완료
                                  </PercentageText>
                                </ProgressBarContainer>
                              </RollingContentWrap2>
                              <RollingContentWrap3>
                                <RollingContentTitle3>보너스 롤링완료까지 필요한 배팅금액:</RollingContentTitle3>
                                {moneySortRadio === 'NORMAL_MONEY' ? (
                                  <>
                                    <RollingContentSportWrap>
                                      <RollingContentSportTypeBox>
                                        <RollingContentSportTypeImg
                                          src={`${process.env.PUBLIC_URL}/img/rolling/rolling_sports.png`}
                                        />
                                        <RollingContentSportTypeTitle>
                                          스포츠 실시간/단폴더
                                        </RollingContentSportTypeTitle>
                                        <RollingContentSportTypeMoney>
                                          {formatAmount(necessarySportsSingleLiveBettingAmount)}
                                        </RollingContentSportTypeMoney>
                                      </RollingContentSportTypeBox>
                                      <RollingContentSportTypeBox>
                                        <RollingContentSportTypeImg
                                          src={`${process.env.PUBLIC_URL}/img/rolling/rolling_sports.png`}
                                        />
                                        <RollingContentSportTypeTitle>스포츠 두폴더</RollingContentSportTypeTitle>
                                        <RollingContentSportTypeMoney>
                                          {formatAmount(necessarySportsTwoBettingAmount)}
                                        </RollingContentSportTypeMoney>
                                      </RollingContentSportTypeBox>
                                      <RollingContentSportTypeBox>
                                        <RollingContentSportTypeImg
                                          src={`${process.env.PUBLIC_URL}/img/rolling/rolling_sports.png`}
                                        />
                                        <RollingContentSportTypeTitle>스포츠 다폴더</RollingContentSportTypeTitle>
                                        <RollingContentSportTypeMoney>
                                          {formatAmount(necessarySportsMultiBettingAmount)}
                                        </RollingContentSportTypeMoney>
                                      </RollingContentSportTypeBox>
                                    </RollingContentSportWrap>
                                    <RollingContentSportWrap>
                                      <RollingContentSportTypeBox style={{ width: '50%' }}>
                                        <RollingContentSportTypeImg
                                          src={`${process.env.PUBLIC_URL}/img/rolling/rolling_virtual.png`}
                                        />
                                        <RollingContentSportTypeTitle>BET365</RollingContentSportTypeTitle>
                                        <RollingContentSportTypeMoney>
                                          {formatAmount(necessaryVirtualBettingAmount)}
                                        </RollingContentSportTypeMoney>
                                      </RollingContentSportTypeBox>
                                      <RollingContentSportTypeBox style={{ width: '50%' }}>
                                        <RollingContentSportTypeImg
                                          src={`${process.env.PUBLIC_URL}/img/rolling/rolling_mini.png`}
                                        />
                                        <RollingContentSportTypeTitle>미니게임</RollingContentSportTypeTitle>
                                        <RollingContentSportTypeMoney>
                                          {formatAmount(necessaryMiniGameBettingAmount)}
                                        </RollingContentSportTypeMoney>
                                      </RollingContentSportTypeBox>
                                    </RollingContentSportWrap>
                                    <RollingContentSportWrap>
                                      <RollingContentSportTypeBox style={{ width: '50%' }}>
                                        <RollingContentSportTypeImg
                                          src={`${process.env.PUBLIC_URL}/img/rolling/rolling_live.png`}
                                        />
                                        <RollingContentSportTypeTitle>카지노</RollingContentSportTypeTitle>
                                        <RollingContentSportTypeMoney>
                                          {formatAmount(necessaryCasinoBettingAmount)}
                                        </RollingContentSportTypeMoney>
                                      </RollingContentSportTypeBox>
                                      <RollingContentSportTypeBox style={{ width: '50%' }}>
                                        <RollingContentSportTypeImg
                                          src={`${process.env.PUBLIC_URL}/img/rolling/rolling_slot.png`}
                                        />
                                        <RollingContentSportTypeTitle>슬롯</RollingContentSportTypeTitle>
                                        <RollingContentSportTypeMoney>
                                          {formatAmount(necessarySlotBettingAmount)}
                                        </RollingContentSportTypeMoney>
                                      </RollingContentSportTypeBox>
                                    </RollingContentSportWrap>
                                  </>
                                ) : (
                                  <>
                                    <RollingContentSportWrap>
                                      <RollingContentSportTypeBox style={{ width: '100%' }}>
                                        <RollingContentSportTypeImg
                                          src={`${process.env.PUBLIC_URL}/img/rolling/rolling_holdem.png`}
                                        />
                                        <RollingContentSportTypeTitle>홀덤</RollingContentSportTypeTitle>
                                        <RollingContentSportTypeMoney>
                                          {formatAmount(necessaryHoldemBettingAmount)}
                                        </RollingContentSportTypeMoney>
                                      </RollingContentSportTypeBox>
                                    </RollingContentSportWrap>
                                  </>
                                )}
                              </RollingContentWrap3>
                            </RollingWrap>
                          </RollingTopWrap>
                        </>
                      )}
                      {selectedIndex === 1 && (
                        <MoneyWithdrawalBodyTwoWrap>
                          <ContentRowWrap2>
                            <CustomButtonWrap>
                              <CustomDeleteButton
                                onClick={() => {
                                  setCheckRadio(content.map(value => value.moneyWithdrawalKey))
                                }}
                                type="button"
                              >
                                <CustomButtonInfos>전체선택</CustomButtonInfos>
                              </CustomDeleteButton>
                              <CustomDeleteButton
                                onClick={() => {
                                  onDeleteHandler()
                                }}
                                variant="primary"
                                type="button"
                              >
                                <FaTrash />
                                <CustomButtonInfos>삭제</CustomButtonInfos>
                              </CustomDeleteButton>
                            </CustomButtonWrap>
                          </ContentRowWrap2>
                          <ReactTableBase
                            columns={moneyWithdrawalListData.tableHeaderData}
                            data={rows}
                            tableConfig={tableConfig}
                          />
                        </MoneyWithdrawalBodyTwoWrap>
                      )}
                      {selectedIndex === 2 && (
                        <MoneyWithdrawalRuleWrap>
                          <MoneyWithdrawalRuleContentWrap>
                            {ReactHtmlParser(withdrawalRuleContent, { transform: transformFn })}
                            <TableWrap>
                              <table>
                                <tbody>
                                  {/* <tr>
                                    <TableFirstTd style={{ width: '16%' }} />
                                    <TableHaderTd style={{ width: '14%' }}>실시간/단폴</TableHaderTd>
                                    <TableHaderTd style={{ width: '14%' }}>두폴이상</TableHaderTd>
                                    <TableHaderTd style={{ width: '14%' }}>세폴이상</TableHaderTd>
                                    <TableHaderTd style={{ width: '14%' }}>미니게임</TableHaderTd>
                                    <TableHaderTd style={{ width: '14%' }}>카지노</TableHaderTd>
                                    <TableHaderTd style={{ width: '14%' }}>슬롯</TableHaderTd>
                                  </tr>
                                  <tr>
                                    <TableBodyTd style={{ fontSize: '11px' }}>
                                      필요롤링
                                      <br />
                                      (신규 첫충)
                                    </TableBodyTd>
                                    <TableBodyTd>{newSingleLiveFolderRolling} % </TableBodyTd>
                                    <TableBodyTd>{newTwoFolderRolling} % </TableBodyTd>
                                    <TableBodyTd>{newMultiFolderRolling} % </TableBodyTd>
                                    <TableBodyTd>{newMiniGameRolling} % </TableBodyTd>
                                    <TableBodyTd>{newCasinoRolling} % </TableBodyTd>
                                    <TableBodyTd>{newSlotRolling} % </TableBodyTd>
                                  </tr>
                                  <tr>
                                    <td />
                                  </tr> */}

                                  <tr>
                                    <TableFirstTd style={{ width: '12%' }} />
                                    <TableHaderTd style={{ width: '12%' }}>실시간/단폴</TableHaderTd>
                                    <TableHaderTd style={{ width: '12%' }}>두폴이상</TableHaderTd>
                                    <TableHaderTd style={{ width: '12%' }}>세폴이상</TableHaderTd>
                                    <TableHaderTd style={{ width: '11%' }}>BET365</TableHaderTd>
                                    <TableHaderTd style={{ width: '11%' }}>미니게임</TableHaderTd>
                                    <TableHaderTd style={{ width: '10%' }}>카지노</TableHaderTd>
                                    <TableHaderTd style={{ width: '10%' }}>슬롯</TableHaderTd>
                                    <TableHaderTd style={{ width: '10%' }}>홀덤</TableHaderTd>
                                  </tr>

                                  <tr>
                                    <TableBodyTd style={{ fontSize: '11px' }}>
                                      필요롤링
                                      <br />
                                      <span style={{ fontSize: '10px' }}>(매일 첫충)</span>
                                    </TableBodyTd>
                                    <TableBodyTd>{firstSingleLiveFolderRolling} % </TableBodyTd>
                                    <TableBodyTd>{firstTwoFolderRolling} % </TableBodyTd>
                                    <TableBodyTd>{firstMultiFolderRolling} % </TableBodyTd>
                                    <TableBodyTd>{firstVirtualGameRolling} % </TableBodyTd>
                                    <TableBodyTd>{firstMiniGameRolling} % </TableBodyTd>
                                    <TableBodyTd>{firstCasinoRolling} % </TableBodyTd>
                                    <TableBodyTd>{firstSlotRolling} % </TableBodyTd>
                                    <TableBodyTd>{firstHoldemRolling} % </TableBodyTd>
                                  </tr>
                                  <tr>
                                    <td />
                                  </tr>

                                  <tr>
                                    <TableFirstTd style={{ width: '12%' }} />
                                    <TableHaderTd style={{ width: '12%' }}>실시간/단폴</TableHaderTd>
                                    <TableHaderTd style={{ width: '12%' }}>두폴이상</TableHaderTd>
                                    <TableHaderTd style={{ width: '12%' }}>세폴이상</TableHaderTd>
                                    <TableHaderTd style={{ width: '11%' }}>BET365</TableHaderTd>
                                    <TableHaderTd style={{ width: '11%' }}>미니게임</TableHaderTd>
                                    <TableHaderTd style={{ width: '10%' }}>카지노</TableHaderTd>
                                    <TableHaderTd style={{ width: '10%' }}>슬롯</TableHaderTd>
                                    <TableHaderTd style={{ width: '10%' }}>홀덤</TableHaderTd>
                                  </tr>

                                  <tr>
                                    <TableBodyTd style={{ fontSize: '11px' }}>
                                      필요롤링
                                      <br />
                                      <span style={{ fontSize: '10px' }}>(매일 매충)</span>
                                    </TableBodyTd>
                                    <TableBodyTd>{everySingleLiveFolderRolling} % </TableBodyTd>
                                    <TableBodyTd>{everyTwoFolderRolling} % </TableBodyTd>
                                    <TableBodyTd>{everyMultiFolderRolling} % </TableBodyTd>
                                    <TableBodyTd>{everyVirtualGameRolling} % </TableBodyTd>
                                    <TableBodyTd>{everyMiniGameRolling} % </TableBodyTd>
                                    <TableBodyTd>{everyCasinoRolling} % </TableBodyTd>
                                    <TableBodyTd>{everySlotRolling} % </TableBodyTd>
                                    <TableBodyTd>{everyHoldemRolling} % </TableBodyTd>
                                  </tr>

                                  <tr>
                                    <td />
                                  </tr>

                                  <tr>
                                    <TableFirstTd style={{ width: '12%' }} />
                                    <TableHaderTd style={{ width: '12%' }}>실시간/단폴</TableHaderTd>
                                    <TableHaderTd style={{ width: '12%' }}>두폴이상</TableHaderTd>
                                    <TableHaderTd style={{ width: '12%' }}>세폴이상</TableHaderTd>
                                    <TableHaderTd style={{ width: '11%' }}>BET365</TableHaderTd>
                                    <TableHaderTd style={{ width: '11%' }}>미니게임</TableHaderTd>
                                    <TableHaderTd style={{ width: '10%' }}>카지노</TableHaderTd>
                                    <TableHaderTd style={{ width: '10%' }}>슬롯</TableHaderTd>
                                    <TableHaderTd style={{ width: '10%' }}>홀덤</TableHaderTd>
                                  </tr>

                                  <tr>
                                    <TableBodyTd style={{ fontSize: '11px' }}>
                                      필요롤링
                                      <br />
                                      (보너스 없음)
                                    </TableBodyTd>
                                    <TableBodyTd>{singleLiveFolderRollingNotBonus} % </TableBodyTd>
                                    <TableBodyTd>{twoFolderRollingNotBonus} % </TableBodyTd>
                                    <TableBodyTd>{multiFolderRollingNotBonus} % </TableBodyTd>
                                    <TableBodyTd>{virtualGameRollingNotBonus} % </TableBodyTd>
                                    <TableBodyTd>{miniGameRollingNotBonus} % </TableBodyTd>
                                    <TableBodyTd>{casinoRollingNotBonus} % </TableBodyTd>
                                    <TableBodyTd>{slotRollingNotBonus} % </TableBodyTd>
                                    <TableBodyTd>{holdemRollingNotBonus} % </TableBodyTd>
                                  </tr>
                                </tbody>
                              </table>
                            </TableWrap>
                          </MoneyWithdrawalRuleContentWrap>
                        </MoneyWithdrawalRuleWrap>
                      )}
                    </ContentInfo>
                  </ContentWrap>
                </>
              )}
            </MobileTopWrap>
          </SignUpModalContentWrap>
        </SignUpModalInner>
      </SignUpModalWrapper>
    </Portal>
  )
}

export default MobileMoneyWithdrawalForm

const PageOverlayWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  // align-items: center;
  text-align: center;
`

const PageOverlayImg = styled.img`
  width: 120px;
  height: 120px;
  margin-bottom: 15px;
`

const PageOverlayContent = styled.div`
  color: #fff;
  font-size: 14px;
`

const PageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.6);
  padding-top: 100px;
`

// endregion
const RollingTopWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding-right: 20px;
  // margin-top: 20px;
  padding-top: 20px;
`
const RollingWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 10px 3px;
  justify-content: center;
  gap: 20px;
  // background: #1c1f21;
`

const RollingHeaderWrap = styled.div`
  // height: 50px;
  width: 100%;
  display: flex;
  padding-left: 7px;
  align-items: flex-start;
`

const RollingHeaderInfo1 = styled.div`
  font-size: 15px;
  color: #fff;
`

const RollingHeaderInfo2 = styled.div`
  font-size: 15px;
  color: ${HermesMainText};
`

const RollingContentWrap1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 7px;
  align-items: flex-start;
`

const RollingContentBox = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 10px;
`

const RollingCompleteImg = styled.img`
  width: 36px;
  height: 36px;
`

const RollingContentTitleWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`

const RollingContentTitle1 = styled.div`
  font-size: 13px;
  color: ${HermesMainText};
`

const RollingContentTitle2 = styled.div`
  font-size: 12px;
`

const RollingContentWrap2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const ProgressBarContainer = styled.div`
  width: 100%;
  height: 35px;
  background-color: #000;
  position: relative;
  border-radius: 7px;
`

const FilledBar = styled.div`
  height: 100%;
  width: ${props => (props.percentage >= 100 ? '100%' : `${props.percentage}%`)};
  background-color: ${props => (props.percentage >= 100 ? 'green' : `${HermesMainBorder}`)};
  border-radius: 7px;
`

const PercentageText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* 가로, 세로 중앙 정렬 */
  color: #fff;
  font-size: 13px;
  font-weight: 600;
`

const RollingContentWrap3 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const RollingContentTitle3 = styled.div`
  font-size: 12px;
  color: #fff;
`

const RollingContentSportWrap = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
`

const RollingContentSportTypeBox = styled.div`
  width: 33.3%;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
  background: #2f3438;
  padding-top: 5px;
`

const RollingContentSportTypeImg = styled.img`
  width: 33px;
  height: 27px;
`
const RollingContentSportTypeTitle = styled.div`
  font-size: 11px;
  color: #fff;
  font-weight: 500;
`

const RollingContentSportTypeMoney = styled.div`
  font-size: 12px;
  color: ${HermesMainText};
`

// endregion
export const MobileListButtonWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: auto;
  padding-left: 5px;
  gap: 5px;
`

export const CustomButtonWrap = styled(MobileListButtonWrap)`
  padding: 0px 10px;
`

export const CustomButtonInfos = styled(CustomButtonInfo)``

export const CustomDeleteButton = styled.button`
  background-color rgb(44, 52, 59);
  border: none;
  border-radius: 3px;
  display: flex;
  width: max-content;
  min-width: 50px;
  padding: 0px 5px;
  height: 42px;
  text-align:center;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 5px;
    color: grey;
  }
`

const SignUpModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden !important;
  outline: 0;
`
const SignUpModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  width: 100%;
`

const SignUpModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  width: 100vw;
  background: #16171a !important;
  height: 100%;
  margin: 0 auto;
  overflow: auto;
`

const SignUpModalHeader = styled.div`
  position: fixed;
  background: #16171a !important;
  top: 0;
  // border 양옆 1px 생각해서
  width: calc(100% - 2px);
  background-color: black;

  height: 60px;
  // border-bottom: 1px solid #7b808c;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 980;
`

// 로고
const SignUpModalHeaderLogo = styled.img`
  width: auto;
  height: 35px;
`

// 닫기 아이콘
const SignUpModalCloseButton = styled.img`
  width: 25px;
  height: 25px;

  position: absolute;
  right: 16px;

  &:hover {
    cursor: pointer;
  }
`

const ContentWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0px;
`

const ContentRowThirdWrap = styled.div`
  border-bottom: solid 1px #252525;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 62px; /* 혹은 다른 높이 값으로 설정 */
`

const ContentRowSecondWrap = styled.div`
  // margin-top: 30px;
  gap: 5px;
  display: flex;
  padding-bottom: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border: solid 1px red;
  span {
    color: #fff;
  }
`

const ContentRowBig = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    flex: 1;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    font-weight: 700 !important;
    font-family: 'Malgun Gothic', dotum;
    height: 62px;
    width: 80px;
    &:not(:last-child) {
      margin-right: 10px;
    }

    ${({ selectedIndex }) =>
      selectedIndex !== null &&
      `
      &:nth-child(${selectedIndex + 1}) {
        border-bottom: solid 1px ${HermesMainBorder};
      }
    `}
  }
`
const ContentRowSmall = styled.div`
  width: 50%;
  color: #fff;
  display: flex;
  align-items: center; /* Optional: to align items vertically in the center */
  justify-content: space-between;
  padding-left: 20px;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
    font-size: 12px;
    font-weight: 500;
  }
  & > img {
    margin-left: auto;
  }
`
const ContentRowWrap2 = styled.div`
  border-bottom: solid 1px #252525;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 42px;
  svg {
    width: 20px;
    height: 20px;
    color: #fff;
    margin-right: 20px;
  }
`

const ContentRowWrap = styled.div`
  border-bottom: solid 1px #252525;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 62px;
  margin-top: 15px;
  padding-left: 25px;
  svg {
    width: 20px;
    height: 20px;
    color: #fff;
    margin-right: 20px;
  }
`

// modal body content
const SignUpModalContentWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  // text-align: center;
  position: absolute;
  top: 60px;
  padding-bottom: 5px;
  // font-weight: 300;
`

const ContentInfo = styled.div`
  width: 100%;
`

const CustomMoneyFormModifyButtomInfo = styled(MoneyFormModifyButtomInfo)`
  font-size: 14px;
  font-weight: 700;
`
const MoneyButton = styled.button`
  width: 150px;
  margin-top: 30px;
  height: 45px;
  background: ${HermesMainBorder};
  border-radius: 6px !important;
  font-size: 12px;
  outline: none;
  color: #fff;
  font-weight: 300;
  border: 2px solid ${HermesMainBorder};
`

export const MoneyFormButton = styled.button`
  width: 100px;
  height: 35px;
  border: none;
  border-radius: 2px;
  background: #343438;
`

const CustomCenterFormField = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
`

const CustomerCenterFormGroup = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
`

const CustomerCenterFormLabel = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  text-align: left;
`

const MoneyWithdrawalHeaderThirdInfo = styled.span`
  font-style: normal;
  font-size: 12px;
  line-height: 23px;
  margin-bottom: 10px;
  /* 엘로우폰트 */

  color: #ffc01a;
`

const MoneyWithdrawalHeaderTwoWrap = styled.div`
  height: 50px;
  width: 100%;
  padding: 20px 0 10px 0px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px !important;

  border-bottom: 1px solid #edae07;
`

const MoneyWithdrawalBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 10px 0px;

  gap: 10px;

  .react-table__column-header {
    font-size: 12px;
    font-weight: 700;
    padding: 16px 0;
  }

  thead th {
    padding: 0;
  }

  tbody tr td {
    font-family: 'Malgun Gothic', dotum;
    padding: 14px 0;
    font-size: 12px;
    font-weight: 700;
  }
`

const MoneyWithdrawalWrap = styled.div`
  background: #000000;
  border: 1px solid #4a4a4a;

  height: 100%;
  min-height: 550px;

  position: relative;
  top: 30px;
`

const MoneyWithdrawalHeaderWrap = styled.div`
  height: 130px;

  padding: 20px 0 10px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px !important;

  // border: 1px solid red;
  border-bottom: 1px solid #edae07;
`

const MoneyWithdrawalHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  margin-bottom: 10px;
  /* 엘로우폰트 */

  color: #ffc01a;
`
export const MoneyWithdrawalCardSubhead = styled.p`
  text-transform: none;
  font-size: 12px;
  line-height: 14px;
  opacity: 0.7;
  margin-top: 3px;
  margin-bottom: 5px;
  color: ${colorMainText};
`

const MoneyWithdrawalBodyWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  // justify-content: center;

  padding: 10px 2px;

  gap: 10px;
`

export const MoneyWithdrawalInfoWrap = styled.div`
  margin: 5px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const MoneyWithdrawalRuleWrap = styled.div`
  background: #141517 !important;
  padding: 10px;
  box-sizing: border-box;
  display: block;
  font-family: 'Malgun Gothic', dotum;
  color: rgb(255, 255, 255);
  font-size: 13px;
`

const MoneyWithdrawalRuleContentWrap = styled.div`
  word-break: keep-all;
`

const MoneyWithdrawalRuleImgWrap = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 160px;
    height: auto;
    margin-top: 80px;
  }
`

const MoneyWithdrawalRuleInfo1 = styled.div`
  color: #878e99;
  word-break: keep-all;
  text-align: center;
  font-size: 14px;
  margin-top: 15px;
  display: block;
  position: relative;
`

const MoneyWithdrawalRuleInfo2 = styled.div`
  text-align: center;
  margin-top: 30px;
  box-sizing: border-box;
  display: block;
  position: relative;

  span {
    border-radius: 5px;
    color: ${HermesMainText};
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
    padding: 10px 20px 10px 20px;
    text-align: center;
  }
`

const TableWrap = styled.div`
  width: 100%;
  margin-top: 40px;
  overflow-x: auto;

  table {
    width: 100%;
    height: 100%;
    background-color: transparent;
    font-size: 12px;
    margin: 0;
    border-spacing: 0;
    border-collapse: collapse;
    margin-inline-start: auto;
    margin-inline-end: auto;
    display: table;
    text-indent: initial;
    border-color: gray;
    color: #fff;
    word-break: keep-all;

    font-family: 'Malgun Gothic', dotum;
  }

  tbody {
    box-sizing: border-box;
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  tr {
    display: table-row;
    vertical-align: baseline;
    border-color: inherit;
    font-size: 12px;
  }
`

const TableFirstTd = styled.td`
  background: #b5b5b7;
  border: 1px solid #b5b5b7;
  color: #fff;
  height: 22px;
  font-size: 11px;
  text-align: center !important;
  padding: 0;
  box-sizing: border-box;
  display: table-cell;
  vertical-align: inherit;
`

const TableHaderTd = styled.td`
  text-align: center !important;
  font-size: 11px !important;
  padding: 0 !important;
  padding-top: 3px !important;
  box-sizing: border-box;
`

const TableBodyTd = styled.td`
  background: #191919;
  border: 1px solid #fff;
  color: ${HermesMainText};
  height: 40px;
  font-size: 12px;
  text-align: center !important;
  padding: 0 !important;
  vertical-align: middle;
  font-weight: 600;
`
