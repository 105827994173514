import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { createUserMoneyDeposit, searchCoinTicker } from '../../../api/memberManage/memberManageApi'
import {
  fetchCoinInfoCompleteAction,
  fetchCoinInfoSubscriptionAction,
  updateCoinInfoUSDT,
} from '../../../redux/memberInfoSlice'
import { CustomSelect } from '../../../shared/components/CustomSelect'
import { MoneyFormInputWrap, MoneyFormModifyButtomInfo } from '../../../shared/components/form/newForm'
import { HermesMainBorder, HermesMainHover2, HermesMainText } from '../../../utils/palette'
import { commonReg2 } from '../../../utils/validate/commonValidate'

const CoinDepositModalContent = ({ applyDepositInfo, onCloseCoinModalHandler }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  // 1 USDT  => {coinPrice} KRW
  const { usdtCoinInfo, coinWalletAddr } = useSelector(state => {
    const { memberInfo } = state

    return {
      usdtCoinInfo: memberInfo.coinInfo.USDT,
      coinWalletAddr: memberInfo.coinWalletAddr,
    }
  })

  const [applyUSDT, setApplyUSDT] = useState(0)

  const fetchCoinTicker = async () => {
    const params = {
      targetCurrency: 'USDT',
    }

    await searchCoinTicker(params).then(res => {
      dispatch(updateCoinInfoUSDT(res.data.bidPrice))
      dispatch(fetchCoinInfoSubscriptionAction())
    })
  }

  useEffect(() => {
    fetchCoinTicker()

    return () => {
      dispatch(fetchCoinInfoCompleteAction())
    }
  }, [])

  useEffect(() => {
    const resultUSDT = !usdtCoinInfo?.bidPrice
      ? 0
      : Math.ceil((applyDepositInfo?.applyMoney || 0) / usdtCoinInfo.bidPrice)

    setApplyUSDT(resultUSDT)
  }, [usdtCoinInfo])

  const [coinExchange, setCoinExchange] = useState(null)
  const [userCoinAddress, setUserCoinAddress] = useState(null)

  const onSubmit = e => {
    if (loading) return

    if (!userCoinAddress) {
      alert('테더 지갑주소를 다시 확인해주세요.')
      return
    }

    if (!coinExchange) {
      alert('거래소를 다시 확인해주세요.')
      return
    }

    setLoading(true)

    const body = {
      moneySort: applyDepositInfo.moneySort,
      moneyType: applyDepositInfo.moneyType,
      applyMoney: applyDepositInfo.applyMoney,
      moneyDepositBonusKey: applyDepositInfo.moneyDepositBonusKey,
      bonusTitle: applyDepositInfo.bonusTitle,
      bonusContent: applyDepositInfo.bonusContent,
      applyCoinAmount: applyUSDT,
      userCoinAddress,
      coinExchange,
    }

    createUserMoneyDeposit(body)
      .then(res => {
        alert('입금신청이 완료되었습니다.')
        onCloseCoinModalHandler()
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'MONEY-1007':
            alert('현재 처리중인 입금 신청이 존재합니다.\n입금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1008':
            alert('현재 처리중인 출금 신청이 존재합니다.\n출금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1009':
            alert('현재 머니이동을 처리중입니다.\n잠시 후 다시 시도해주세요.')
            break
          case 'ASSET-1010':
            alert('입금이 불가능한 시간입니다.')
            break
          case 'ASSET-1004':
            alert(message)
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('입금신청이 실패했습니다. 다시 시도해주세요.')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <ContentWrap>
        <ContentInnerScrollWrap>
          <ContentInnerWrap className="sidebar-container">
            <SubMenuItemListWrap>
              <div>
                <SubMenuItemBox active>코인(USDT) 입금하기</SubMenuItemBox>
              </div>
            </SubMenuItemListWrap>

            <ContentBox>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '70%' }}>
                  <MoneyDepositInfoWrap>
                    <MoneyDepositInfoLeftWrap>
                      <MoneyDepositInfoLeftBox>
                        <MoneyDepositInfoLeftTextBox>
                          <div>신청 입금금액(₩)</div>
                          <MoneyDepositInfoLeftText>
                            {`${(applyDepositInfo?.applyMoney || 0)?.toString().replace(commonReg2, ',')}`} 원
                          </MoneyDepositInfoLeftText>
                        </MoneyDepositInfoLeftTextBox>
                      </MoneyDepositInfoLeftBox>
                    </MoneyDepositInfoLeftWrap>
                  </MoneyDepositInfoWrap>
                  <Form
                    onSubmit={onSubmit}
                    initialValues={{
                      usdtCoinInfo,
                    }}
                  >
                    {({ handleSubmit, form: { reset } }) => (
                      <div onSubmit={handleSubmit}>
                        <ContentRowSecondWrap>
                          <CustomerCenterFormGroup style={{ marginBottom: '-15px' }}>
                            <CustomerCenterFormLabel>현재 시세 - 1 USDT (1초 갱신)</CustomerCenterFormLabel>
                            <CustomCenterFormField>
                              <Field name="applyMoney">
                                {({ input, meta }) => (
                                  <MoneyFormInputWrap>
                                    <MoneyFormInput
                                      {...input}
                                      type="text"
                                      value={`${usdtCoinInfo?.bidPrice || 0} KRW`}
                                      disabled
                                    />
                                  </MoneyFormInputWrap>
                                )}
                              </Field>
                            </CustomCenterFormField>
                          </CustomerCenterFormGroup>
                          <CustomerCenterFormGroup style={{ marginBottom: '-15px' }}>
                            <CustomerCenterFormLabel>테더 입금주소</CustomerCenterFormLabel>
                            <CustomCenterFormField>
                              <Field name="applyMoney">
                                {({ input, meta }) => (
                                  <MoneyFormInputWrap>
                                    <MoneyFormInput {...input} type="text" value={coinWalletAddr} disabled />
                                  </MoneyFormInputWrap>
                                )}
                              </Field>
                            </CustomCenterFormField>
                          </CustomerCenterFormGroup>
                          <CustomerCenterFormGroup style={{ marginBottom: '-15px' }}>
                            <CustomerCenterFormLabel>테더 입금수량</CustomerCenterFormLabel>
                            <CustomCenterFormField>
                              <Field name="applyMoney">
                                {({ input, meta }) => (
                                  <MoneyFormInputWrap>
                                    <MoneyFormInput {...input} type="text" value={`${applyUSDT} USDT`} disabled />
                                  </MoneyFormInputWrap>
                                )}
                              </Field>
                            </CustomCenterFormField>
                          </CustomerCenterFormGroup>
                          <CustomerCenterFormGroup style={{ marginBottom: '-15px' }}>
                            <CustomerCenterFormLabel>보내는 거래소명</CustomerCenterFormLabel>
                            <CustomCenterFormField>
                              <Field name="applyMoney">
                                {({ input, meta }) => (
                                  <MoneyFormInputWrap>
                                    <MoneyFormInput
                                      {...input}
                                      type="text"
                                      value={coinExchange || 'ex) 업비트'}
                                      onChange={e => {
                                        setCoinExchange(e.target.value)
                                      }}
                                    />
                                  </MoneyFormInputWrap>
                                )}
                              </Field>
                            </CustomCenterFormField>
                          </CustomerCenterFormGroup>
                          <CustomerCenterFormGroup style={{ marginBottom: '-15px' }}>
                            <CustomerCenterFormLabel>보내는 테더주소</CustomerCenterFormLabel>
                            <CustomCenterFormField>
                              <Field name="applyMoney">
                                {({ input, meta }) => (
                                  <MoneyFormInputWrap>
                                    <MoneyFormInput
                                      {...input}
                                      type="text"
                                      value={userCoinAddress}
                                      onChange={e => {
                                        setUserCoinAddress(e.target.value)
                                      }}
                                    />
                                  </MoneyFormInputWrap>
                                )}
                              </Field>
                            </CustomCenterFormField>
                          </CustomerCenterFormGroup>
                          <MoneyButton
                            variant="primary"
                            type="button"
                            onClick={() => {
                              onSubmit()
                            }}
                          >
                            <CustomButtonInfo2>테더 입금하기</CustomButtonInfo2>
                          </MoneyButton>
                        </ContentRowSecondWrap>
                      </div>
                    )}
                  </Form>
                </div>
              </div>
            </ContentBox>
          </ContentInnerWrap>
        </ContentInnerScrollWrap>
      </ContentWrap>
    </>
  )
}

export default CoinDepositModalContent

export const MoneyFormInput = styled.input`
  width: 100%;
  padding: 0 10px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 35px;

  color: #d3d3d3;

  border: transparent;
  background: transparent;

  outline: none;

  &:disabled {
    // background: #848484;
    &:hover {
      cursor: not-allowed;
      caret-color: red;
    }
  }
`

const CustomButtonInfo2 = styled(MoneyFormModifyButtomInfo)`
  color: #000 !important;
  font-size: 14px;
  font-weight: 700;
`

const ContentWrap = styled.div`
  color: #fff;
  border-radius: 0px;
  background: rgb(16, 16, 18);
  border: none;
  box-shadow: none;
  font-family: 'Malgun Gothic', dotum;
  display: block;
  position: relative;
`

// 스크롤 적용하기 위함
const ContentInnerScrollWrap = styled.div`
  // height: 550px;
  height: calc(100vh - 220px);
  overflow: hidden;
  position: relative;
  display: block;
  color: #fff;
`

const ContentInnerWrap = styled.div`
  overflow: auto;
  height: 100%;
  display: block;
`

const SubMenuItemListWrap = styled.div`
  border-bottom: 1px solid rgb(41, 41, 41);
  display: flex;
  align-items: center;
  justify-content: center;
`

const SubMenuItemBox = styled.div`
  padding: 16px 50px;
  float: left;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    border-bottom: 1px solid ${HermesMainBorder};
  }

  ${props =>
    props.active &&
    `
    border-bottom: 1px solid ${HermesMainBorder};
    `}
`

const ContentBox = styled.div`
  width: 100%;
  padding: 24px;
  display: block;
`

const MoneyDepositHeaderThirdInfoBox = styled.div`
  float: left;
  padding-top: 10px;
  height: 40px;
  display: block;
`

const MoneyDepositHeaderThirdInfo = styled.span`
  color: #fff;
  font-size: 14px;
`

const MoneyDepositHeaderTwoWrap = styled.div`
  padding: 8px 8px 8px 16px;
  border-radius: 6px;
  background: #171719;
  font-weight: 900;
  display: flex;
`

const MoneyDepositBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 20px 0;

  gap: 10px;

  .react-table__column-header {
    font-size: 14px;
    font-weight: 700;
    padding: 10px 0;
  }

  tbody tr td {
    font-family: 'Malgun Gothic', dotum;
    padding: 16px 5px;
    font-size: 14px;
    font-weight: 700;
  }
`

const MoneyDepositInfoWrap = styled.div`
  font-weight: 700;
  // padding-left: 24px;
  // padding-bottom: 24px;
  margin-bottom: -24px;
  padding-top: 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  font-family: 'Malgun Gothic', dotum;
`

const MoneyDepositInfoLeftWrap = styled.div`
  background: rgb(18, 18, 20);
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
`
const MoneyDepositInfoLeftBox = styled.div`
  padding: 16px 0px;
  border-radius: 6px;
`

const MoneyDepositInfoLeftTextBox = styled.div`
  float: left;
  padding-left: 0px;
  font-weight: 700;
  font-size: 16px;
`
const MoneyDepositInfoLeftText = styled.div`
  color: ${HermesMainHover2};
  font-size: 15px;
`

const MoneyDepositInfoRightWrap = styled.div`
  background: rgb(18, 18, 20);
  width: calc(50% - 8px);
  float: right;
  display: block;
`
const MoneyDepositInfoRightBox = styled.div`
  padding: 16px 24px;
  border-radius: 6px;
`

const MoneyDepositInfoRightTextBox = styled.div`
  float: right;
  text-align: right;
  padding-right: 24px;
`
const MoneyDepositInfoRightText = styled.div`
  color: ${HermesMainHover2};
  font-size: 14px;
`

const MoneyButton = styled.button`
  width: 100%;
  margin-top: 30px;
  height: 45px;
  background: ${HermesMainBorder};
  border-radius: 6px !important;
  outline: none;
  color: #fff;
  font-weight: 300;
  border: 2px solid ${HermesMainBorder};
`

const ContentRowSecondWrap = styled.div`
  gap: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    color: #fff;
  }
`

const CustomerCenterFormGroup = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const CustomCenterFormField = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
`

const CustomerCenterFormLabel = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 500;
  text-align: left;
  font-size: 14px;
`

export const CouponSelect = styled(CustomSelect)`
  .react-select__control {
    height: 28px;
    border: 1px solid #4a4a4a;
    background-color: transparent;
    &.react-select__control--is-focused,
    &:hover {
      border: solid 2px;
      border-color: white !important;
      box-shadow: none;
      background: transparent;
      .react-select__placeholder {
        color: #d3d3d3;
      }
    }
  }

  .react-select__input {
    height: 28px;
    color: #d3d3d3 !important;
    &.react-select__control--is-focused {
      color: red !important;
    }
  }

  .react-select__menu {
    z-index: 101;
  }

  .react-select__menu-list {
    overflow: auto !important;
    background: rgba(0, 0, 0, 0.7) !important;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    color: ${HermesMainText};
  }

  .react-select__single-value {
    color: #d3d3d3;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    border: 1px solid black !important;
    color: #fff;
    font-weight: 600;
  }

  .react-select__option {
    color: #fff;
  }

  .react-select__option--is-focused,
  .react-select__option--is-selected {
    background-color: rgb(16, 16, 18);
  }
`
