export const JackpotGameType = {
  CASINO: 'CASINO',
  SLOT: 'SLOT',
  SPORTS: 'SPORTS',
  MINI_GAME: 'MINI_GAME',
  HOLDEM: 'HOLDEM',
  VIRTUAL_GAME: 'VIRTUAL_GAME',
}

export const JackpotGameTypeKr = {
  CASINO: '카지노',
  SLOT: '슬롯',
  SPORTS: '스포츠',
  MINI_GAME: '미니게임',
  HOLDEM: '홀덤',
  VIRTUAL_GAME: '가상게임',
}
