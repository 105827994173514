import React from 'react'
import { commonReg2 } from '@/utils/validate/commonValidate'
import { MemberDepositStatusEnums } from '@/utils/enums/MyPage/myPageEnums'
import { convertToKstByFormat } from '@/utils/dateTime'
import DepositDeleteRadio from '../../../containers/MoneyDeposit/DepositDeleteRadio'
import { DepositSortEnums } from '../../../utils/enums/MemberHistory/MemberDepositEnums'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const MobileMoneyDepositListData = (content, checkRadio, setCheckRadio) => {
  const columns = [
    {
      Header: '선택',
      accessor: 'deleteRadio',
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <DepositDeleteRadio rowInfo={original} setCheckRadio={setCheckRadio} checkRadio={checkRadio} />
      },
    },
    {
      Header: '원화/테더',
      accessor: 'depositSort',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.depositSort) {
          case 'KRW_MONEY':
            return (
              <button
                type="button"
                style={{ background: 'none', border: 'none', color: '#fff', fontWeight: '900', padding: '2px 5px' }}
              >
                {DepositSortEnums[original.depositSort]}
              </button>
            )
          case 'COIN_MONEY':
            return (
              <button type="button" style={{ background: 'none', border: 'none', color: '#fff', fontWeight: '900' }}>
                {DepositSortEnums[original.depositSort]}
              </button>
            )

          default:
            return (
              <button type="button" style={{ background: 'red', border: 'none', color: '#fff', fontWeight: '900' }}>
                {DepositSortEnums.KRW_MONEY}
              </button>
            )
        }
      },
    },
    {
      Header: '신청액(원/USDT)',
      accessor: 'applyMoneyMember',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.applyCoinAmount != null) {
          return (
            <span>
              {original.applyMoney?.toString().replace(commonReg2, ',')}원 /{' '}
              {original.applyCoinAmount?.toString()?.replace(commonReg2, ',')}USDT
            </span>
          )
        }
        return <span>{original.applyMoney?.toString().replace(commonReg2, ',')}원 / -</span>
      },
    },
    {
      Header: '처리일',
      accessor: 'executeDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 90,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstByFormat(original.executeDate, 'MM-DD HH:mm')
      },
    },
    {
      Header: '상태',
      accessor: 'status',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 30,
      Cell: r => {
        const { row } = r
        const { original } = row
        return MemberDepositStatusEnums[original.status]
      },
    },
  ]
  return { tableHeaderData: columns, tableRowsData: content }
}

export default MobileMoneyDepositListData
