export const SocketIORequestMessageType = {
  /**
   * INIT
   * 로그인 X 조회가 필요한 것
   * 스포츠 경기 수, 실시간 입출금, 공지/이벤트 게시글 등
   * */
  HOME_INIT: 'HOME_INIT',
  POPUP_INIT: 'POPUP_INIT',
  SPORT_INIT: 'SPORT_INIT',
  VIRTUAL_INIT: 'VIRTUAL_INIT',

  /**
   * Auth - USER
   * */
  USER_LOGIN: 'USER_LOGIN',
  AUTHENTICATION: 'AUTHENTICATION',
  RE_AUTHENTICATION: 'RE_AUTHENTICATION',

  /**
   * User
   * */
  USER_INFO: 'USER_INFO',
  USER_NOTE: 'USER_NOTE',
  USER_ONE_TO_ONE: 'USER_ONE_TO_ONE',

  /**
   * Log
   * */
  CLIENT_USER_LOG: 'CLIENT_USER_LOG',

  /**
   * 스포츠
   * */
  SPORTS_FIXTURE_LIST: 'SPORTS_FIXTURE_LIST',
  SPORTS_FIXTURE_MARKET_LIST: 'SPORTS_FIXTURE_MARKET_LIST',
  UPDATE_PREMATCH: 'UPDATE_PREMATCH',
  UPDATE_INPLAY: 'UPDATE_INPLAY',
  SPORTS_BETTING: 'SPORTS_BETTING',

  SPORTS_FIXTURE_DOMESTIC_SUBSCRIPTION: 'SPORTS_FIXTURE_DOMESTIC_SUBSCRIPTION',
  SPORTS_FIXTURE_OVERSEAS_SUBSCRIPTION: 'SPORTS_FIXTURE_OVERSEAS_SUBSCRIPTION',
  SPORTS_FIXTURE_SPECIAL_SUBSCRIPTION: 'SPORTS_FIXTURE_SPECIAL_SUBSCRIPTION',
  SPORTS_FIXTURE_LIVE_SUBSCRIPTION: 'SPORTS_FIXTURE_LIVE_SUBSCRIPTION',

  SPORTS_FIXTURE_DOMESTIC_COMPLETE: 'SPORTS_FIXTURE_DOMESTIC_COMPLETE',
  SPORTS_FIXTURE_OVERSEAS_COMPLETE: 'SPORTS_FIXTURE_OVERSEAS_COMPLETE',
  SPORTS_FIXTURE_SPECIAL_COMPLETE: 'SPORTS_FIXTURE_SPECIAL_COMPLETE',
  SPORTS_FIXTURE_LIVE_COMPLETE: 'SPORTS_FIXTURE_LIVE_COMPLETE',

  /**
   * 가상게임
   */
  VIRTUAL_FIXTURE_LIST: 'VIRTUAL_FIXTURE_LIST',

  /**
   * 홀덤
   */
  HOLDEM_GAME_LINK: 'HOLDEM_GAME_LINK',
  WILD_GAMES_GAME_LINK: 'WILD_GAMES_GAME_LINK',

  /**
   * 코인
   */

  COIN_INFO_SUBSCRIPTION: 'COIN_INFO_SUBSCRIPTION',
  COIN_INFO_COMPLETE: 'COIN_INFO_COMPLETE',
}
